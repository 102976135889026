import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Utils } from 'src/app/utils/common-functions';
import { loginService } from 'src/app/services/login/login.service';
import { HttpService } from '../../../services/http/http.service';
import { Observable, throwError, from } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { DataService } from 'src/app/services/data/data.service';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessageService } from 'src/app/services/message/message.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatKeyboardComponent } from 'angular-onscreen-material-keyboard';
import { CustomValidators } from 'src/app/utils/custom-validators';
import { AddnomineeService } from '../../my-policies/addnominee-service/addnominee.service';


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  resetPwdForm: FormGroup;
  resetPwdFormValid: boolean = false;
  firstFocus: boolean = false;
  secondFocus: boolean = false;
  currentFocus: boolean = false;
  clientSecureLoginCheck: boolean = false;
  isFirstMasked: boolean = true;
  isSecondMasked: boolean = true;
  isCurrentMasked: boolean = true;
  isLoggedIn: boolean = false;
  statusMessage: string;
  passwordChangedResp: any;
  oldPasswordType;
  currentPasswordType;
  confirmPasswordType

  @Input() currentPasswordFlag: boolean = false;
  validateUserResp: any;

  constructor(private fb: FormBuilder, private _snackBar: MatSnackBar, public dialog: MatDialog, private router: Router, public utils: Utils, public loginService: loginService, public _httpService: HttpService,
    public dataService: DataService, public auth: AuthService, private message: MessageService,public addnomineeService: AddnomineeService) { }

  ngOnInit(): void {
    this.oldPasswordType = 'password';
    this.currentPasswordType = 'password';
    this.confirmPasswordType = 'password';
    this.validateUserResp = this.addnomineeService.getDeleteNomineeDtls();
    if(this.validateUserResp.length == 0) {
      this.router.navigate(['/']);
    }
    if(this.utils.isUserAuthenticated()) {
      this.currentPasswordFlag = true;
    }
    /* if ((this.dataService.getValidateUserResp() == null) && (this.dataService.getLoginResp() == null)) {
      this.router.navigate(['/']);
    } */
    if (this.utils.getExpiredFlag() =="Y") {
      this.statusMessage = "Your password has expired , Kindly change your password"
    }
    //console.log(this.dataService.getValidateUserResp(), this.dataService.getLoginResp())
    this.resetPwdForm = this.fb.group({
      current: ['', [Validators.required]],
      //current: ['', [Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,11}')]],
      first: ['', [Validators.required, CustomValidators.newPassword]],
      second: ['', [Validators.required, CustomValidators.newPassword]],
    });

    if (this.utils.getLocalData('loggedIn', false)) {
      this.isLoggedIn = true;
    } else {
      this.isLoggedIn = false;
      this.resetPwdForm.removeControl('current');
    }
    if (this.currentPasswordFlag == false) {
      this.resetPwdForm.removeControl('current');
    }
  }

  get validation() { return this.resetPwdForm.controls; }

  firstFocusOut(event: any) {
    this.firstFocus = true;
  }
  secondFocusOut(event: any) {
    this.secondFocus = true;
  }
  currentFocusOut(event: any) {
    this.currentFocus = true;
  }
  showKeyboard(event: any) {
    this.clientSecureLoginCheck = (event.checked);
  }
  submitResetPwdForm() {
    if (this.currentPasswordFlag) {
      if(this.resetPwdForm.controls.first.value != this.resetPwdForm.controls.second.value){
        this.statusMessage = "New password and Re-enter password do not match"
      }
      else if(this.resetPwdForm.controls.first.value === this.resetPwdForm.controls.current.value){
        this.statusMessage = "New password is same as current password. Please enter a different password."
      }
      else{      
        this.changepasswords();
      }
    }
    else {
      if (this.resetPwdForm.controls.first.value != this.resetPwdForm.controls.second.value) {
        this.statusMessage = "New password and Re-enter password do not match"
      }
      else {  
        this.setPassword();  
      }
    }
    // if(this.isLoggedIn) {
    //   this.loginService.logout();
    //   this.router.navigate(['/']); 
    // } else {
    //   this.router.navigate(['/']); 
    // }

  }
  // toggleFirstInputType(img, input) {
  //   if (this.isFirstMasked) {
  //     input.type = 'password';
  //     img.src = 'assets/eye_closed.svg';
  //   }
  //   else {
  //     input.type = 'text';
  //     img.src = 'assets/eye.png';
  //   }
  // }
  // toggleSecInputType(img, input) {
  //   console.log(input.type)
  //   this.isSecondMasked = !this.isSecondMasked;
  //   if (this.isSecondMasked) {
  //     input.type = 'password';
  //     img.src = 'assets/eye_closed.svg';
  //   }
  //   else {
  //     input.type = 'text';
  //     img.src = 'assets/eye.png';
  //   }
  // }
  toggleFirstInputType(img) {
    this.isFirstMasked = !this.isFirstMasked;
    if (this.isFirstMasked) {
      this.currentPasswordType = 'password';
      img.src = 'assets/eye_closed.svg';
    }
    else {
      this.currentPasswordType = 'text';
      img.src = 'assets/eye.png';
    }
  }
  toggleSecInputType(img) {
    this.isSecondMasked = !this.isSecondMasked;
    if (this.isSecondMasked) {
      this.confirmPasswordType = 'password';
      img.src = 'assets/eye_closed.svg';
    } else {
      this.confirmPasswordType = 'text';
      img.src = 'assets/eye.png';
    }
  }
  redirectToLogin() {
    if (this.utils.getLocalData('loggedIn', false) && this.utils.getExpiredFlag() !="Y") {
      this.router.navigate(["/my-policies"]);
    }
    else if (this.utils.getExpiredFlag() =="Y") {
      this.auth.logout('A');
    }
    else { this.router.navigate(['/']); }
  }

  // toggleCurrentInputType(img, input) {
  //   this.isCurrentMasked = !this.isCurrentMasked;
  //   if (this.isCurrentMasked) {
  //     input.type = 'password';
  //     img.src = 'assets/eye_closed.svg';
  //   }
  //   else {
  //     input.type = 'text';
  //     img.src = 'assets/eye.png';
  //   }
  // }
  toggleCurrentInputType(img) {
    this.isCurrentMasked = !this.isCurrentMasked;
    if (this.isCurrentMasked) {
      this.oldPasswordType = 'password';
      img.src = 'assets/eye_closed.svg';
    }
    else {
      this.oldPasswordType = 'text';
      img.src = 'assets/eye.png';
    }
  }
  changepasswords() {
    let loginDtlResp = this.dataService.getLoginResp();
    let changepasswordReq = {
      currentPaasswordEnc: this.resetPwdForm.controls.current.value,
      newPasswordEnc: this.resetPwdForm.controls.first.value,
      confirmPasswordEnc: this.resetPwdForm.controls.second.value,
      //modeOfChange: "CO",
      //clientID: loginDtlResp.CLIENT_ID,
      //userRk: loginDtlResp.USER_RK,

    }
    return this.loginService.changePassword(changepasswordReq)
      .subscribe((response) => {
        if ((this._httpService.isSuccessResponse(response))) {
          this.passwordChangedResp = response.body;
          this.statusMessage = "Password updated successfully. Please login again"
          this.message.openSnackBar(this.statusMessage, 'xy', ['success-snackbar']);
          this.auth.logout('A');
          this.router.navigate(['/']);
        }
        else {
          this.statusMessage = response.head.errordetails.message;
        }
      }),
      catchError((error) => throwError(error));
  }
  setPassword() {
    let setpasswordReq
    let loginDtlResp = this.dataService.getLoginResp();
    let userDtlResp = this.dataService.getValidateUserResp();
    if (this.isLoggedIn) {
      setpasswordReq = {
        createpassword: this.resetPwdForm.controls.first.value,
        confirmpassword: this.resetPwdForm.controls.second.value,
        clientID: loginDtlResp.CLIENT_ID,
        userRk: loginDtlResp.USER_RK,
      }
    }
    else {
      if (this.validateUserResp)
        setpasswordReq = {
          createpassword: this.resetPwdForm.controls.first.value,
          confirmpassword: this.resetPwdForm.controls.second.value,
          clientID: this.validateUserResp.CLIENT_ID,
          userRk: this.validateUserResp.USER_RK,
        }
    }
    return this.loginService.ResetPassword(setpasswordReq)
      .subscribe((response) => {
        if (response && response.body.message == "Password successfully updated") {
          this.passwordChangedResp = response.body;
          this.statusMessage = "Password updated successfully. Please login again"
          this.message.openSnackBar(this.statusMessage, 'xy', ['success-snackbar']);
          this.auth.logout('A');
          this.router.navigate(['/']);
        }
        else {
          this.statusMessage = response.body.suberrormsg;
        }
      }),
      catchError((error) => throwError(error));
  }
  clearError() {
    this.statusMessage = "";
  }
  blockPaste(e) { e.preventDefault() }
}
