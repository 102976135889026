<div class="sidebanner">
    <mat-tab-group class="side-banner-tabs" [disableRipple]=true headerPosition="below" mat-align-tabs="center" [disablePagination]=true  animationDuration="300ms" [(selectedIndex)]="activeTabIndex" preserveContent>
        <!-- <mat-tab label="userManual">
            <ng-template mat-tab-label>
                <div class='tab-label' (click)="utils.trackUserActions({ 'eventName' : 'Scroll on creatives' })">1</div>
            </ng-template>
            <div class="sidebannercontent user-manual-tab">
                <img src="assets/user_manual.png" class="sidebanner-img user-manual-img">
                <div class="sidebannertext">
                    <div class="sidebanner-padding">
                        <div class="approval">Navigation made simple! </div>
                    </div>
                    <div class="hdfc-text">
                        <div class="hdfc-text-one"><span>HDFC</span> LIFE </div>
                        <div class="side-span">My Account Portal </div>
                        <div class="side-span">User Guide</div>
                    </div>
                    <ul>
                        <li>Click on download now</li>
                        <li>Step by step user guide</li>
                        <li>Access anytime</li>
                    </ul>
                    <div class="sidebanner-btn">
                        <button mat-button [appDecorate] searchId='35' class="sidebanner-button" (click)="downloadUserManual()">Download
                            Now</button>
                    </div>
                </div>
            </div>
        </mat-tab> -->
        <mat-tab label="whatsapp">
            <ng-template mat-tab-label>
               <!-- <div class='tab-label' (click)="utils.trackUserActions({ 'eventName' : 'Scroll on creatives' })">2</div> -->
            </ng-template>
            <div class="sidebannercontent">
                <img src="assets/sidebanner_img.jpg" class="sidebanner-img">
                <div class="sidebannertext">
                    <div class="sidebanner-padding">
                        <div class="approval">Check policy details </div>
                        <div class="approval">on WhatsApp with</div>
                    </div>
                    <div class="hdfc-text">
                        <div class="hdfc-text-one"><span>HDFC</span> LIFE </div>
                        <div class="side-span">WhatsApp Bot Etty</div>
                    </div>
                    <ul>
                        <li>Say Hi on +91 8291 890 569 to get started</li>
                        <li>Available in regional languages</li>
                        <li>Available 24*7</li>
                    </ul>
                    <div class="sidebanner-btn">
                        <button mat-button class="sidebanner-button" (click)="onBtnClick()">Know more
                            &#8250;</button>
                    </div>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>
