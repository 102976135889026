import { Component, OnInit, Inject, Output, EventEmitter, HostBinding } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { DataService } from 'src/app/services/data/data.service';
import { loginService } from 'src/app/services/login/login.service';
import { HttpService } from 'src/app/services/http/http.service';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Utils } from "../../../../utils/common-functions";
import { AuthService } from 'src/app/services/auth/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessageService } from 'src/app/services/message/message.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import * as moment from 'moment';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-logout-dialog',
  templateUrl: './logout-dialog.component.html',
  styleUrls: ['./logout-dialog.component.css']
})
export class LogoutDialogComponent implements OnInit {
  MyaccfeedbackForm: FormGroup;
  CommentsForm: FormGroup;
  RecommendForm: FormGroup;
  userDetails: any = {};
  userFeedback :any;
  isHNIUSer: boolean = false;
  queryQuestion: boolean = true;
  firstForm = false;
  secondForm = false;
  poorRatingform = false;
  thirdForm = false;
  ratedval = "";
  likely = "";
  clientID = this.utils.getClientId()
  statusMessage: string;
  othersChecked: boolean = false;
  displayOption: boolean = false;
  quesChecked: boolean = false;
  comment: boolean = false;
  ImprovementForm: FormGroup;
  firstVal: string = "";
  secVal: string;
  thirdVal: string;
  fourthVal: string;
  fifthVal: string;
  sixthVal: string;
  seventhVal: string;
  eightVal: string;
  vocQues: any;
  feedBackQues: any;
  firstQues: any;
  secondQues: any;
  thirdQues: any;
  fourthQues: any;
  fifthQues: any;
  sixthQues: any;
  seventhQues: any;
  eighthQues: any;
  others: any;
  aspects: any;
  commentsQues: any;
  ratingQues: any;
  disableButton: boolean = false;
  disableSubmit: boolean = false;
  questions: any = [];
  selectedValues: Array<any> = [];
  agentConnect: boolean;
  portalSupport: boolean;
  suggestionFlag: boolean;
  finalMessage: boolean;
  feedBackForm: FormGroup;
  today= new Date();
  files: any[] = [];
  @HostBinding('class.fileover') fileOver: boolean;
  @Output() fileDropped = new EventEmitter<any>();
  uploadedFileList: Array<any> = [];
  byteArray: Array<any> = [];
  fileFormats: Array<string> = ['jpeg', 'jpg', 'pdf', 'png', 'gif', 'bmp'];
  maxFileSize: number = 5;
  maxNumOfFiles: number = 2;
  policyNo :Array<any> =[];
  policies: Array<any> = [];
  feedBackResponse: any;
  rk_value: any;
  recordCreated: boolean;
  get device() {
    return this.deviceService.getDeviceInfo();
  }
  constructor(@Inject(MAT_DIALOG_DATA) public data, public dialog: MatDialog, public loginService: loginService, private _snackBar: MatSnackBar, private message: MessageService, public utils: Utils, public dataService: DataService, public _httpService: HttpService, private fb: FormBuilder, public auth: AuthService,private deviceService: DeviceDetectorService) { }

  ngOnInit(): void {
    this.userDetails = this.data.userDtls;
    this.clientID = this.data.clientid;
    this.firstForm  = true;
    this.queryQuestion = false;
    if (this.userDetails && this.userDetails.HNI_FLG === "Y") {
      this.isHNIUSer = true;
    }
    this.MyaccfeedbackForm = this.fb.group({
      rating: new FormControl('', Validators.required),
    });
    this.CommentsForm = this.fb.group({
      comments: ['', [Validators.required, Validators.minLength(3)]]
    });
    this.RecommendForm = this.fb.group({
      ratings: new FormControl('', Validators.required)
    });
    this.ImprovementForm = this.fb.group({
      others: ['']
    });
    this.feedBackForm = this.fb.group({
      suggestions: ['', Validators.required],
      policyNo: ['', Validators.required],
      // uploadImage: ['', Validators.required],
      date: ['', Validators.required],
      time: ['', Validators.required]
    })
    this.getVocQues();
    this.policyNo = this.data.policyCardResp;
    for(let item of this.policyNo) {
      this.policies.push(item.POLICYNO);
    }
    if(this.policies.length == 1) {
      this.feedBackForm.get('policyNo').setValue(this.policies[0])
    }
  }
  get Commentvalidation() { return this.CommentsForm.controls; }
  get Feedbacktvalidation() { return this.MyaccfeedbackForm.controls; }
  get Recommendvalidation() { return this.RecommendForm.controls; }
  get ImprovementValidation() { return this.ImprovementForm.controls; }

  getVocQues() {
    let reqBody = {
      userid: this.clientID,
    }
    return this.loginService.getVocQuestions(reqBody)
      .subscribe((response) => {
        this.vocQues = response.body.vocQnsArr
        this.feedBackQues = this.vocQues[0].QUESTION_DESC;
        this.aspects = this.vocQues[1].QUESTION_DESC;
        this.commentsQues = this.vocQues[6].QUESTION_DESC;
        this.firstQues = this.vocQues[2].QUESTION_DESC;
        this.secondQues = this.vocQues[3].QUESTION_DESC;
        this.thirdQues = this.vocQues[4].QUESTION_DESC;
        this.fourthQues = this.vocQues[5].QUESTION_DESC;
        this.fifthQues = this.vocQues[8].QUESTION_DESC;
        this.sixthQues = this.vocQues[9].QUESTION_DESC;
        this.seventhQues = this.vocQues[10].QUESTION_DESC;
        this.eighthQues = this.vocQues[11].QUESTION_DESC;
        this.others = this.vocQues[12].QUESTION_DESC;
        this.ratingQues = this.vocQues[7].QUESTION_DESC;
      })
  }
  queChecked(event: MatCheckboxChange) {
    if (event.checked) {
      this.selectedValues.push(event.source.value);
      this.quesChecked = true
      this.ImprovementForm.get('others').clearValidators();
      this.ImprovementForm.get('others').setErrors(null);
    } else {
      var index = this.selectedValues.indexOf(event.source.value)
      this.selectedValues.splice(index, 1)
      if (this.selectedValues.length == 0) {
        this.selectedValues = [];
        this.quesChecked = false
        this.ImprovementForm.get('others').clearValidators();
        this.ImprovementForm.get('others').setErrors(null);
      }
    }
  }
  otherChecked(event: MatCheckboxChange) {
    this.othersChecked = (event.checked);
    if(event.checked) {
    this.ImprovementForm.get('others').setValidators(Validators.required)
    this.quesChecked = true
    }
    else {
      this.ImprovementForm.get('others').clearValidators();
      this.ImprovementForm.get('others').setErrors(null);
      this.quesChecked = false;
      this.ImprovementForm.updateValueAndValidity();
    }
  }
  feedbackData() {
    this.loginService.getUserFeedback("");
  }
  firstField() {
    this.firstForm = true;
    this.thirdForm = false;
    this.secondForm = false;
    this.poorRatingform = false;
    this.disableButton = this.MyaccfeedbackForm.valid;
    this.ratedval = ""
  }
  fivestars(event: Event) {
    this.MyaccfeedbackForm.value.rating = "5";
    this.MyaccfeedbackForm.controls.rating.setValue(event),
      this.ratedval = "Excellent";
    this.submitFirstField();
  }
  fourstars(event: Event) {
    this.MyaccfeedbackForm.value.rating = "4";
    this.MyaccfeedbackForm.controls.rating.setValue(event),
      this.ratedval = "Very Good";
    this.submitFirstField();
  }
  threestars(event: Event) {
    this.MyaccfeedbackForm.value.rating = "3";
    this.MyaccfeedbackForm.controls.rating.setValue(event),
      this.ratedval = "Fair";
    this.submitFirstField();
  }
  twostars(event: Event) {
    this.MyaccfeedbackForm.value.rating = "2";
    this.MyaccfeedbackForm.controls.rating.setValue(event),
      this.ratedval = "Poor";
    //this.poorRatingform = true;
    this.submitFirstField();
  }
  onestar(event: Event) {
    this.MyaccfeedbackForm.value.rating = "1";
    this.MyaccfeedbackForm.controls.rating.setValue(event),
      this.ratedval = "Very Poor";
    //this.poorRatingform = true;
    this.submitFirstField();
  }
  secondField() {
    let reqBody = {
      clientid: this.clientID,
      QUS1_RK: this.vocQues[0].QUESTION_RK,
      QUS1_ANS: this.MyaccfeedbackForm.value.rating,
      RK: this.rk_value?this.rk_value:"",
      ACTION: this.rk_value?"UPDATE":"INSERT",
      device_type:this.device.deviceType,
      browser:`${this.device.browser} - ${this.device.browser_version}`,
      os_type:this.device.os,
    }
   return this.loginService.submitVocRatingFeedback(reqBody)
      .subscribe((response) => {
        if ((this._httpService.isSuccessResponse(response))) {
          this.userFeedback = response.body.STATUS;
          this.rk_value = response.body.RK;
          if((this.userFeedback).toUpperCase() == "SUCCESS") {
          this.thirdForm = false;
          this.secondForm = true;
          this.poorRatingform = false;
          this.disableSubmit = this.RecommendForm.valid
          this.likely = ""
          this.quesChecked = false
          this.selectedValues = [];
          if (this.MyaccfeedbackForm.value.rating == "2" || this.MyaccfeedbackForm.value.rating == "1") {
            this.poorRatingform = true;
            this.questions = [this.firstQues,
            this.secondQues, this.thirdQues, this.fourthQues,
            this.fifthQues,
            this.sixthQues,
            this.seventhQues,
            this.eighthQues,
            ]
         }
        }
        else {
          let trackMsgObj ={"flowType": 'VOC - Submit','policyNo' : this.feedBackForm.controls.policyNo.value.replace(/\s+/g,''),"error_code":"UEE"};        
          this.message.openSnackBar(response.head.errordetails.message,'xy',['error-snackbar'],trackMsgObj); 
         }
      }
     else {
      let trackMsgObj ={"flowType": 'VOC - Submit','policyNo' : this.feedBackForm.controls.policyNo.value.replace(/\s+/g,''),"error_code":"UEE"};        
      this._httpService.apiErrorHandler(response,null,trackMsgObj);
    }
      }),
      catchError((error) => throwError(error));
  }
  poorRatingField() {
    this.firstForm = false;
    this.secondForm = false;
    this.thirdForm = false;
    this.poorRatingform = true;
    this.questions = [this.firstQues,
    this.secondQues, this.thirdQues, this.fourthQues,
    this.fifthQues,
    this.sixthQues,
    this.seventhQues,
    this.eighthQues,
    ]
  }
  thirdField() {
    this.thirdForm = true;
    this.secondForm = false;
    this.firstForm = false;
    this.poorRatingform = false;
  }
  noneoften() {
    this.RecommendForm.value.rating = "0";
    this.likely = "Not at all likely";
  }
  oneoften() {
    this.RecommendForm.value.rating = "1"; this.likely = "Not at all likely";
  }
  twooften() {
    this.RecommendForm.value.rating = "2"; this.likely = "Not at all likely";
  }
  threeoften() {
    this.RecommendForm.value.rating = "3"; this.likely = "Not at all likely";
  }
  fouroften() {
    this.RecommendForm.value.rating = "4"; this.likely = "Not at all likely";
  }
  fiveoften() {
    this.RecommendForm.value.rating = "5"; this.likely = "Not at all likely";
  }
  sixoften() {
    this.RecommendForm.value.rating = "6"; this.likely = "Not at all likely";
  }
  sevenoften() {
    this.RecommendForm.value.rating = "7"; this.likely = "May be";
  }
  eightoften() {
    this.RecommendForm.value.rating = "8"; this.likely = "May be";
  }
  nineoften() {
    this.RecommendForm.value.rating = "9"; this.likely = "Extremely likely";
  }
  tenoften() {
    this.RecommendForm.value.rating = "10"; this.likely = "Extremely likely";
  }

  submitFirstField() {
    if (this.MyaccfeedbackForm.value.rating != "") {
      this.secondField();
    }
  }
  submitSecondField() {
    if (this.MyaccfeedbackForm.value.rating == "1" || this.MyaccfeedbackForm.value.rating == "2") {
      this.poorRatingField();
    }
    else { this.thirdField(); }
  }
  submitPoorRatingField() {
    for (let j = 0; j <= this.questions.length; j++) {
      if (this.selectedValues[j] == this.firstQues) { this.firstVal = "Y" }
      else if (this.selectedValues[j] == this.secondQues) { this.secVal = "Y" }
      else if (this.selectedValues[j] == this.thirdQues) { this.thirdVal = "Y" }
      else if (this.selectedValues[j] == this.fourthQues) { this.fourthVal = "Y" }
      else if (this.selectedValues[j] == this.fifthQues) { this.fifthVal = "Y" }
      else if (this.selectedValues[j] == this.sixthQues) { this.sixthVal = "Y" }
      else if (this.selectedValues[j] == this.seventhQues) { this.seventhVal = "Y" }
      else if (this.selectedValues[j] == this.eighthQues) { this.eightVal = "Y" }
      else {
        if (this.firstVal != "Y" && j == 0) {
          this.firstVal = ""
        } else if (this.secVal != "Y" && j == 1) {
          this.secVal = ""
        } else if (this.thirdVal != "Y" && j == 2) {
          this.thirdVal = ""
        } else if (this.fourthVal != "Y" && j == 3) {
          this.fourthVal = ""
        } else if (this.fifthVal != "Y" && j == 4) {
          this.fifthVal = ""
        } else if (this.sixthVal != "Y" && j == 5) {
          this.sixthVal = ""
        } else if (this.seventhVal != "Y" && j == 6) {
          this.seventhVal = ""
        } else if (this.eightVal != "Y" && j == 7) {
          this.eightVal = ""
        }
      }
    }
    this.thirdField();
  }
  submitForm() {
    this.feedbackservice();
    //this.dialog.closeAll();
  }
  feedbackservice() {
    let feedbackDataReq = {
      userid: this.clientID,
      "voc_detls": [
        {
          "voc_qusans": this.MyaccfeedbackForm.value.rating,
          "voc_quscd": this.vocQues[0].QUESTION_CD,
          "voc_qusrk": this.vocQues[0].QUESTION_RK,
          "voc_anstype": this.vocQues[0].ANSWER_TYPE
        },
        {
          "voc_quscd": this.vocQues[1].QUESTION_CD,
          "voc_qusrk": this.vocQues[1].QUESTION_RK,
          "voc_anstype": this.vocQues[1].ANSWER_TYPE
        },
        {
          "voc_qusans": this.CommentsForm.value.comments,
          "voc_quscd": this.vocQues[6].QUESTION_CD,
          "voc_qusrk": this.vocQues[6].QUESTION_RK,
          "voc_anstype": this.vocQues[6].ANSWER_TYPE
        },
        {
          "voc_qusans": this.firstVal,
          "voc_quscd": this.vocQues[2].QUESTION_CD,
          "voc_qusrk": this.vocQues[2].QUESTION_RK,
          "voc_anstype": this.vocQues[2].ANSWER_TYPE
        },
        {
          "voc_qusans": this.secVal,
          "voc_quscd": this.vocQues[3].QUESTION_CD,
          "voc_qusrk": this.vocQues[3].QUESTION_RK,
          "voc_anstype": this.vocQues[3].ANSWER_TYPE
        },
        {
          "voc_qusans": this.thirdVal,
          "voc_quscd": this.vocQues[4].QUESTION_CD,
          "voc_qusrk": this.vocQues[4].QUESTION_RK,
          "voc_anstype": this.vocQues[4].ANSWER_TYPE
        },
        {
          "voc_qusans": this.fourthVal,
          "voc_quscd": this.vocQues[5].QUESTION_CD,
          "voc_qusrk": this.vocQues[5].QUESTION_RK,
          "voc_anstype": this.vocQues[5].ANSWER_TYPE
        },
        {
          "voc_qusans": this.fifthVal,
          "voc_quscd": this.vocQues[8].QUESTION_CD,
          "voc_qusrk": this.vocQues[8].QUESTION_RK,
          "voc_anstype": this.vocQues[8].ANSWER_TYPE
        },
        {
          "voc_qusans": this.sixthVal,
          "voc_quscd": this.vocQues[9].QUESTION_CD,
          "voc_qusrk": this.vocQues[9].QUESTION_RK,
          "voc_anstype": this.vocQues[9].ANSWER_TYPE
        },
        {
          "voc_qusans": this.seventhVal,
          "voc_quscd": this.vocQues[10].QUESTION_CD,
          "voc_qusrk": this.vocQues[10].QUESTION_RK,
          "voc_anstype": this.vocQues[10].ANSWER_TYPE
        },
        {
          "voc_qusans": this.eightVal,
          "voc_quscd": this.vocQues[11].QUESTION_CD,
          "voc_qusrk": this.vocQues[11].QUESTION_RK,
          "voc_anstype": this.vocQues[11].ANSWER_TYPE
        },
        {
          "voc_qusans": this.ImprovementForm.value.others,
          "voc_quscd": this.vocQues[12].QUESTION_CD,
          "voc_qusrk": this.vocQues[12].QUESTION_RK,
          "voc_anstype": this.vocQues[12].ANSWER_TYPE
        },
        {
          "voc_quscd": this.vocQues[7].QUESTION_CD,
          "voc_qusrk": this.vocQues[7].QUESTION_RK,
          "voc_anstype": this.vocQues[7].ANSWER_TYPE,
          "voc_qusans": this.RecommendForm.value.rating
        }
      ]
    }
    return this.loginService.getUserFeedback(feedbackDataReq)
      .subscribe((response) => {
        if ((this._httpService.isSuccessResponse(response))) {
          this.userFeedback = response.body;
          if (response && response.body == "VOC feedback successful") {
            let mail ="service@hdfclife.com";
            let link = mail.link("mailto:mail");
            this.statusMessage = `Thank you for your valuable feedback! , For any further assistance, please contact us on 022 6844 6530 or email us at ${link}.`
            let trackMsgObj ={"flowType": 'VOC - Submit','policyNo' : this.feedBackForm.controls.policyNo.value.replace(/\s+/g,''),"error_code":"SM"};        
            this.message.openSnackBar(this.statusMessage, 'xy', ['success-snackbar'],trackMsgObj); 
          }
        }
        this.auth.logout('M');
      }),
      catchError((error) => throwError(error));
  }
  viewForms(id: String) {
    if (id == 'logoutForms') {
      this.queryQuestion = false;
      this.firstForm = true;
    }
    else if (id == 'portalSupport') {
      this.portalSupport = true;
      this.agentConnect = false;
    }
    else if (id == 'submit') {
      this.submitFeedback();
    }
  }
  noButton(id: String) {
    if (id == 'supportMessage') {
      this.queryQuestion = false;
      this.agentConnect = true;
    }
    else if (id == 'suggestionMessage') {
      this.agentConnect = false;
      this.suggestionFlag = true;
    }
  }
  close() {
    this.dialog.closeAll();
  }
  submitVocFeedback() {
    this.submitPoorRatingField();
    let reqBody = {
        clientid: this.clientID,
        RK: this.rk_value?this.rk_value:"",
        //QUS1_RK: this.vocQues[0].QUESTION_RK,
        //RATING: this.MyaccfeedbackForm.value.rating,
        QUS2_RK: this.vocQues[6].QUESTION_RK,
        QUS2_ANS: this.CommentsForm.value.comments,
        QUS3_OPT1_RK: this.vocQues[2].QUESTION_RK,
        QUS3_OPT1_ANS: this.firstVal?this.firstVal:"N",
        QUS3_OPT2_RK: this.vocQues[3].QUESTION_RK,
        QUS3_OPT2_ANS: this.secVal?this.secVal:"N",
        QUS3_OPT3_RK: this.vocQues[4].QUESTION_RK,
        QUS3_OPT3_ANS: this.thirdVal?this.thirdVal:"N",
        QUS3_OPT4_RK: this.vocQues[5].QUESTION_RK,
        QUS3_OPT4_ANS: this.fourthVal?this.fourthVal:"N",
        QUS3_OPT5_RK: this.vocQues[8].QUESTION_RK,
        QUS3_OPT5_ANS: this.fifthVal?this.fifthVal:"N",
        QUS3_OPT6_RK: this.vocQues[9].QUESTION_RK,
        QUS3_OPT6_ANS: this.sixthVal?this.sixthVal:"N",
        QUS3_OPT7_RK: this.vocQues[10].QUESTION_RK,
        QUS3_OPT7_ANS: this.seventhVal?this.seventhVal:"N",
        QUS3_OPT8_RK:  this.vocQues[11].QUESTION_RK,
        QUS3_OPT8_ANS: this.eightVal?this.eightVal:"N",
        QUS3_OPT9_RK:  this.vocQues[12].QUESTION_RK,
        QUS3_OPT9_ANS: this.ImprovementForm.value.others?this.ImprovementForm.value.others:"",
        QUS4_RK: this.vocQues[7].QUESTION_RK,
        QUS4_ANS: this.RecommendForm.value.rating,
    }
    this.utils.trackUserActions({ 'eventName' : 'VOC - Submit', 'vocStarRating':this.RecommendForm.value.rating, 'vocComments': this.CommentsForm.value.comments, 'vocOptionsSelected':this.selectedValues, 'vocScaleRating':this.MyaccfeedbackForm.value.rating,'policyNo' : this.feedBackForm.controls.policyNo.value.replace(/\s+/g,''),"clientId" :this.clientID })
    return this.loginService.submitVocQuestionnaire(reqBody)
      .subscribe((response) => {
        if ((this._httpService.isSuccessResponse(response))) {
          this.userFeedback = response.body;
          if (response && (response.body.STATUS).toUpperCase() == "SUCCESS") {
            this.dialog.closeAll();
            let mail ="service@hdfclife.com";
            let link = mail.link("mailto:mail");
            this.statusMessage = `Thank you for your valuable feedback! , For any further assistance, please contact us on 022 6844 6530 or email us at ${link}.`
            let trackMsgObj ={"flowType": 'VOC - Submit','policyNo' : this.feedBackForm.controls.policyNo.value.replace(/\s+/g,''),"error_code":"SM"};        
            this.message.openSnackBar(this.statusMessage, 'xy', ['success-snackbar'],trackMsgObj); 
           this.auth.logout('M');
          }
          else {
            let trackMsgObj ={"flowType": 'VOC - Submit','policyNo' : this.feedBackForm.controls.policyNo.value.replace(/\s+/g,''),"error_code":"UEE"};        
           this.message.openSnackBar(response.head.errordetails.message,'xy',['error-snackbar'],trackMsgObj); 
          }
        }
        else {
          let trackMsgObj ={"flowType": 'VOC - Submit','policyNo' : this.feedBackForm.controls.policyNo.value.replace(/\s+/g,''),"error_code":"UEE"};        
          this._httpService.apiErrorHandler(response,null,trackMsgObj);
        }
      }),
      catchError((error) => throwError(error));
  }
  onDocumentLoad(files) {    
    if (this.validateFiles(files)) {
        this.uploadedFileList = files;
        this.byteArrayGenerator(this.uploadedFileList).then(result => {
          this.byteArray = result;
        });
      }       
  }
  validateFiles(files) {
    let filesExceeded: boolean;
      filesExceeded = files.length > this.maxNumOfFiles;       
    if (filesExceeded) {
      let trackMsgObj ={"flowType": 'VOC - Submit','policyNo' : this.feedBackForm.controls.policyNo.value.replace(/\s+/g,''),"error_code":"VE"};        
      this.message.openSnackBar(`Maximum of ${this.maxNumOfFiles} files can be uploaded`, 'xy', ['error-snackbar'],trackMsgObj);  
      return false;
    }
    for (let i = 0; i < files.length; i++) {
      const filename = files[i].name;
      const filetype = files[i].type ? files[i].type.split('/')[1].toLowerCase() : '';
      const filesize = files[i].size / 1024 / 1024;
      if (!this.fileFormats.includes(filetype)) {
        let trackMsgObj ={"flowType": 'VOC - Submit','policyNo' : this.feedBackForm.controls.policyNo.value.replace(/\s+/g,''),"error_code":"VE"};        
        this.message.openSnackBar(`${filename} has a wrong file format`, 'xy', ['error-snackbar'],trackMsgObj);  
        return false;
      } else if (filesize > this.maxFileSize) {
        let trackMsgObj ={"flowType": 'VOC - Submit','policyNo' : this.feedBackForm.controls.policyNo.value.replace(/\s+/g,''),"error_code":"VE"};        
        this.message.openSnackBar(`${filename} exceeds the maximum file size`, 'xy', ['error-snackbar'],trackMsgObj);
        return false;
      }
    }
    return true;
  }
  async byteArrayGenerator(files: Array<any>) {
    let bytearray = [];
    for (let i = 0; i < files.length; i++) {
      let base64string: string;
      base64string = await this.utils.getBase64(files[i]);
      bytearray.push({
        doctype: files[i].name.split('.').pop(),
        base64image: base64string,
      });
    }
    //this.byteArray = [];
    return bytearray;
  }
  submitFeedback() {
    let reqBody = {
      clientid: this.clientID,
      policy: this.feedBackForm.controls.policyNo.value.replace(/\s+/g,''),
      date: moment(this.feedBackForm.controls.date.value).format('YYYY-MM-DD'),
      time: this.feedBackForm.controls.time.value,
      issue_desc: this.feedBackForm.controls.suggestions.value,
      upload:this.byteArray.length > 0 ? 'Y':'N',
      image: this.byteArray.length > 0 ? this.byteArray :"",
    }
    this.utils.trackUserActions({ 'eventName' : 'VOC - Submit', 'vocStarRating':this.RecommendForm.value.rating, 'vocComments': this.CommentsForm.value.comments, 'vocOptionsSelected':this.selectedValues, 'vocScaleRating':this.MyaccfeedbackForm.value.rating,'policyNo' : reqBody.policy,"clientId" :reqBody.clientid });
    return this.loginService.vocFeedbackSubmit(reqBody)
      .subscribe((response) => {
        if ((this._httpService.isSuccessResponse(response))) {
          this.feedBackResponse = response.body;
          if (response && (response.body.STATUS).toUpperCase() == "SUCCESS") {
            this.dialog.closeAll();
            let mail ="service@hdfclife.com";
            let link = mail.link("mailto:mail");
            let trackMsgObj ={"flowType": 'VOC - Submit','policyNo' : this.feedBackForm.controls.policyNo.value.replace(/\s+/g,''),"error_code":"SM"};        
            this.statusMessage = `Thank you for your valuable feedback! , For any further assistance, please contact us on 022 6844 6530 or email us at ${link}.`
            this.message.openSnackBar(this.statusMessage, 'xy', ['success-snackbar'],trackMsgObj); 
            this.auth.logout('M');
          }
          else {
            let trackMsgObj ={"flowType": 'VOC - Submit','policyNo' : this.feedBackForm.controls.policyNo.value.replace(/\s+/g,''),"error_code":"UEE"};        
           this.message.openSnackBar(response.head.errordetails.message,'xy',['error-snackbar'],trackMsgObj);  
          }
        }
        else {
          let trackMsgObj ={"flowType": 'VOC - Submit','policyNo' : this.feedBackForm.controls.policyNo.value.replace(/\s+/g,''),"error_code":"UEE"};        
          this._httpService.apiErrorHandler(response,null,trackMsgObj);
        }
      }),
      catchError((error) => throwError(error));
  }
  closePopupDialog(){
    this.dialog.closeAll();
    this.utils.trackUserActions({ 'eventName' : 'VOC - close', 'vocStarRating':this.RecommendForm.value.rating, 'vocComments': this.CommentsForm.value.comments, 'vocOptionsSelected':this.selectedValues, 'vocScaleRating':this.MyaccfeedbackForm.value.rating,'policyNo' : this.feedBackForm.controls.policyNo.value.replace(/\s+/g,''),"clientId" :this.clientID });
  }
}
