import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import { SidebannerComponent } from './components/sidebanner/sidebanner.component';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import {MatButtonToggleModule} from '@angular/material/button-toggle';;
import {MatExpansionModule} from '@angular/material/expansion';
import {MatTabsModule} from '@angular/material/tabs';
import { MatDialogModule } from '@angular/material/dialog';
import { MatStepperModule } from '@angular/material/stepper';
import { ReactiveFormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { RecommendedPlansComponent } from './components/recommended-plans/recommended-plans.component';
import { ToasterPopupComponent } from './components/toaster-popup/toaster-popup.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FormsModule } from '@angular/forms';
import { CaptchaComponent } from './components/captcha/captcha.component';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { PolicyNumberDropdownComponent } from './components/policy-number-dropdown/policy-number-dropdown.component';
import { Ng2LoadingSpinnerModule } from 'ng2-loading-spinner';
import { DocListPopupComponent } from './components/doc-list-popup/doc-list-popup.component';
import { DragDropComponent } from './components/drag-drop/drag-drop.component';
import { LogoutDialogComponent } from './components/logout-dialog/logout-dialog.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { SrPopupComponent } from './components/sr-popup/sr-popup.component';
import { onlyDecimalNumberDirective } from './directives/number-only-directive';
import { onlyAlphabeticDirective } from './directives/alphabets-only-directive';
import { ChatBotComponent } from './components/chat-bot/chat-bot.component';
import {CameraComponent} from '../shared/components/camera/camera.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { WebcamModule } from 'ngx-webcam';
import { PaymentRedirectComponent } from '../shared/components/payment-redirect/payment-redirect.component';
import { MessagePopupComponent } from './components/message-popup/message-popup.component';
import { PayoutComponent } from './components/payout/payout.component';
import { FractionInhesDirective } from './directives/fraction-inches-directive';
import { OfflineAadharRedirectComponent } from './components/offline-aadhar-redirect/offline-aadhar-redirect.component';
import { DocUploadRedirectComponent } from './components/doc-upload-redirect/doc-upload-redirect.component';
import { VirtualBranchRedirectComponent } from './components/virtual-branch-redirect/virtual-branch-redirect.component';
import { ExistingNeftComponent } from './components/existing-neft/existing-neft.component';
import { VirtualBranchTestComponent } from './components/virtual-branch-test/virtual-branch-test.component';
import { PolicyBasedLoginComponent } from './components/policy-based-login/policy-based-login.component';
import { PolicyBasedHeaderComponent } from './components/policy-based-header/policy-based-header.component';
import { SwitchPolicyGroupPopupComponent } from './components/switch-policy-group-popup/switch-policy-group-popup.component';
import { CustomSnackbarComponent } from '../shared/components/custom-snackbar/custom-snackbar.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { BankDetailsComponent } from './components/bank-details/bank-details.component';
import { FormatPipe } from './pipes/format.pipe';
import { GenericDocUploadComponent } from './components/generic-doc-upload/generic-doc-upload.component';
import { SsoRedirectComponent } from './components/sso-redirect/sso-redirect.component';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { SearchComponent } from './components/search/search.component';
import { DecorateDirective } from './directives/decorate.directive';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { loanTermsComponent } from './components/loans-terms-and-conditions/loans-terms-and-conditions';
import { SuspenseDocUploadComponent } from './components/suspense-doc-upload/suspense-doc-upload.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import {MatBadgeModule} from '@angular/material/badge';
import { PostFormComponent } from './components/post-form/post-form.component';


@NgModule({
  declarations: [ HeaderComponent, FooterComponent, SidebannerComponent, RecommendedPlansComponent, onlyAlphabeticDirective,
    ToasterPopupComponent, CaptchaComponent, PolicyNumberDropdownComponent, DocListPopupComponent, DragDropComponent, LogoutDialogComponent,SrPopupComponent,onlyDecimalNumberDirective, ChatBotComponent,
    CameraComponent,PaymentRedirectComponent, MessagePopupComponent, PayoutComponent,FractionInhesDirective, OfflineAadharRedirectComponent, DocUploadRedirectComponent, VirtualBranchRedirectComponent, ExistingNeftComponent,VirtualBranchTestComponent,
    PolicyBasedLoginComponent,PolicyBasedHeaderComponent, SwitchPolicyGroupPopupComponent, CustomSnackbarComponent, SsoRedirectComponent, BankDetailsComponent, FormatPipe, GenericDocUploadComponent, TermsAndConditionsComponent, SearchComponent,loanTermsComponent, DecorateDirective, SuspenseDocUploadComponent,PostFormComponent
  ],
  imports: [
    CommonModule,
    MatMenuModule,    
    MatIconModule,
    MatFormFieldModule,
    MatToolbarModule,
    MatCardModule,
    MatSelectModule,
    MatExpansionModule,
    MatTabsModule,
    MatSnackBarModule,    
    MatStepperModule,
    MatRadioModule,
    MatInputModule,
    MatDatepickerModule,
    MatButtonToggleModule,
    MatDialogModule,    
    MatSlideToggleModule,
    FormsModule,
    ReactiveFormsModule,
    MatListModule,
    MatTableModule,
    MatButtonModule,
    MatCheckboxModule,
    WebcamModule,
    MatAutocompleteModule,
    MatTooltipModule,
    MatProgressBarModule,
    NgCircleProgressModule.forRoot({}),
    MatBadgeModule
  ],
  exports: [
    HeaderComponent, 
    FooterComponent,
    SidebannerComponent,
    RecommendedPlansComponent,
    MatMenuModule,
    MatIconModule,
    MatFormFieldModule,
    MatToolbarModule,
    MatCardModule,
    MatSelectModule,
    MatExpansionModule,
    MatTabsModule,
    MatDialogModule,
    MatCheckboxModule,
    MatSnackBarModule,
    ReactiveFormsModule,
    MatStepperModule,
    MatRadioModule,
    MatInputModule,
    MatDatepickerModule,
    MatSlideToggleModule,
    FormsModule,
    ToasterPopupComponent,
    CaptchaComponent,
    MatListModule,
    MatTableModule,
    MatButtonModule,
    PolicyNumberDropdownComponent,
    Ng2LoadingSpinnerModule,
    DocListPopupComponent,
    DragDropComponent,
    MatAutocompleteModule,
    SrPopupComponent,
    onlyDecimalNumberDirective,
    PdfViewerModule,
    WebcamModule, 
    PaymentRedirectComponent,
    MessagePopupComponent,
    PayoutComponent,
    FractionInhesDirective,
    OfflineAadharRedirectComponent,
    DocUploadRedirectComponent,
    VirtualBranchRedirectComponent, 
    VirtualBranchTestComponent,
    PolicyBasedLoginComponent,
    PolicyBasedHeaderComponent,
    MatButtonToggleModule,
    MatPaginatorModule,
    MatSortModule,
    BankDetailsComponent, 
    FormatPipe,
    GenericDocUploadComponent,
    TermsAndConditionsComponent,
    SearchComponent,
    DecorateDirective,
    MatTooltipModule,
    MatProgressBarModule,
    loanTermsComponent, 
    SuspenseDocUploadComponent,
    MatBadgeModule,
    PostFormComponent
  ]
})
export class SharedModule { }
