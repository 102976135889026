<div class="update-mailing-address" [ngClass]="{'virtual-branch': dataService.isVirtualBranch}">
    <div *ngIf="!data.disableClose" mat-dialog-actions>
        <div mat-dialog-close class="close-dialog" [ngClass]="{'hni-close-dialog':isHNIUSer}">
            Close
            <img src="assets/close.svg">
        </div>
    </div>
    <div *ngIf="data.disableClose" mat-dialog-actions (click)="closeDialog()">
        <div class="close-dialog" [ngClass]="{'hni-close-dialog':isHNIUSer}">
            Close
            <img src="assets/close.svg">
        </div>
    </div>
    <div class="update-mailing-address-header" [ngClass]="{'hni-popups-header':isHNIUSer}">
        <span *ngIf="manualDivFlag && addressbody">{{addressbody.dropdown}}</span>
        <span *ngIf="neftFlag && bankdtlbody">{{bankdtlbody.dropdown}}</span> 
        <span *ngIf="chequeFlag && bankdtlbody">{{bankdtlbody.dropdown}}</span> 
        <span *ngIf="kycFlag">Upload your choice of KYC Document</span>              
        <span *ngIf="editNameFlag">Update Account Holder Name</span>
    </div>
    <div class="update-mailing-address-body popup-content-height">
            <div class="update-mailing-address-content" *ngIf="(validKycCount && ocrFlag && !manualFlag) || (!validKycCount && ocrFlag && manualFlag)">
                <div class="content-one">Upload proof</div>
                <div class="content-two">Updates will reflect immediately</div>
            </div>
            <div class="proof-of-address-div" *ngIf="(validKycCount && ocrFlag && !manualFlag) || (!validKycCount && ocrFlag && manualFlag)">
                    <div class="content-three" *ngIf="data.id =='maturity' && neftFlag">Use this option for maturity amount less than or equal to 7 lacs</div>
            <mat-radio-group class="radio-group flex" [formControl]="ocrDocCode" (change)="ocrDocChange($event)">                
                    <mat-radio-button *ngIf="!neftFlag && !chequeFlag" value="DOC_006" class="checkbox-div">
                        Passport
                    </mat-radio-button>
                    <mat-radio-button *ngIf="!neftFlag && !chequeFlag" value="DOC_018" class="checkbox-div">
                        Aadhaar Card
                    </mat-radio-button>                
                    <mat-radio-button *ngIf="!neftFlag && !chequeFlag" value="DOC_501" class="checkbox-div">
                        Valid Voter's Identity Card
                    </mat-radio-button>                    
                    <mat-radio-button *ngIf="neftFlag || chequeFlag" value="DOC_056" class="checkbox-div">
                        Personalized Cancelled Cheque
                    </mat-radio-button>   
            </mat-radio-group>
        </div>        
        <div class="upload-data-div flex" *ngIf="(validKycCount && ocrFlag && !manualFlag) || (!validKycCount && ocrFlag && manualFlag)">
            <div class="upload-file-div margin-right">
                <app-drag-drop #dragDrop (fileDropped)="openOcrPopup($event)"></app-drag-drop>
                <div class="upload-file-content flex">
                    <span>Accepted File types: .jpeg, .png, .pdf, .gif, .bmp</span>
                    <span>Maximum File Size: 2 MB</span>
                </div>
                <div class="upload-file-content-mobile">
                    <span class="file-mobile-span">File types: .jpeg, .png, .pdf, .gif, .bmp</span>
                    <span>Max. File Size: 2 MB</span>
                </div>
            </div>
            <div class="upload-file-div">
                <div class="upload-file margin-left">
                    <input type="file" name accept="image/png, image/jpg, image/pdf" max-file-size="250">
                    <div (click)="openCamera()" class="file-input-content flex"><span class="input-content-span-one">Take a photo of your
                            proof </span><img class="camera-image" src="assets/camera.svg">
                        <div class="file-upload-mobile">Take a photo</div>
                    </div>
                </div>
            </div>
        </div>
         <!-- <div *ngIf="addPermanentAddressFlag && ocrDocCode.value == 'DOC_018' && data.role == 'Owner' && !data.id" class="aadhar-service-div">
            <div class="aadhar-service-note">Note: To update address using aadhaar service, please click on the button</div>
            <div class="button-div flex">
                <button mat-button class="aadhar-service-button" type="button" (click)="openOfflineAadharMsgPopup()">Update with Aadhaar Service</button>
            </div>
            <form #offlineAadharForm [action]="offlineAadharURL" method="POST" target="_self">
                <input type="hidden" name="WEBAPI_REQUEST_DATA" [value]="offlineAadharFormData">
            </form>
        </div>  -->
        <form *ngIf="(!validKycCount && ocrFlag && manualFlag) || (!validKycCount && !ocrFlag && manualFlag)">
            <div class="seperator flex-center" *ngIf="(!validKycCount && ocrFlag && manualFlag && !fetchOcrDetails)">(Or)</div>
            <div *ngIf="entryFlag && !fetchOcrDetails" class="manual-div">
                <div class="manual-content" >
                    <div class="manual-one">Enter Manually</div>
                    <div class="manual-two">Updates typically takes 24-48 hours</div>
                </div>
                <div *ngIf="addPermanentAddressFlag">
                    <mat-radio-button class="checkbox-div manual-checkbox" (click)="updateManual();onManualFlowClick()">Manual Entry,
                        Requires Verification</mat-radio-button>
                </div>
                <div *ngIf="neftFlag">
                        <div class="content-three" *ngIf="data.id=='maturity' && neftFlag">Use this option for maturity amount more than 7 lacs</div>
                    <mat-radio-button class="checkbox-div manual-checkbox" (click)="updateFileDiv(updateNeftDetails);onManualFlowClick()">
                        Manual Entry, Requires Verification</mat-radio-button>
                </div>   
                <!-- <div *ngIf="chequeFlag">
                    <mat-radio-button class="checkbox-div manual-checkbox" (click)="updateFileDiv(updateChequeDetails)">
                        Manual Entry, Requires Verification</mat-radio-button>
                </div>              -->
                <div *ngIf="editNameFlag">
                    <mat-radio-button class="checkbox-div manual-checkbox" (click)="editName(editNamePopup);onManualFlowClick()">Manual
                        Entry, Requires Verification</mat-radio-button>
                </div> 
                <div *ngIf="kycFlag">
                    <mat-radio-button class="checkbox-div manual-checkbox" (click)="createKycSR();onManualFlowClick()">Manual Entry,
                        Requires Verification</mat-radio-button>
                </div>               
            </div>
        </form>
    </div>
</div>
<ng-template #updateFilePopup>
    <div class="update-file-div ocr-update-popup">
        <div mat-dialog-actions class="mat-dialog-actions" *ngIf="!data.disableClose" (click)="cancelNeftDetails();trackUserActions({'action':'close'})">
            <div mat-dialog-close class="close-dialog" [ngClass]="{'hni-close-dialog':isHNIUSer}">
                Close
                <img src="assets/close.svg">
            </div>
        </div>
        <div class="mat-dialog-actions" *ngIf="data.disableClose" (click)="closeDialog();trackUserActions({'action':'close'})">
            <div class="close-dialog" [ngClass]="{'hni-close-dialog':isHNIUSer}">
                Close
                <img src="assets/close.svg">
            </div>
        </div>
        <div class="ocr-update-popup-header" [ngClass]="{'hni-popups-header':isHNIUSer}">
            <span *ngIf="addPermanentAddressFlag && addressbody">{{addressbody.dropdown}}</span>
            <span *ngIf="neftFlag && bankdtlbody">{{bankdtlbody.dropdown}}</span> 
            <span *ngIf="chequeFlag && bankdtlbody">{{bankdtlbody.dropdown}}</span> 
            <span *ngIf="kycFlag">Upload your choice of KYC Document</span>               
            <span *ngIf="editNameFlag">Update Account Holder Name</span>
        </div>
        <div class="ocr-update-popup-content popup-content-height">
            <div class="back-button-div" *ngIf="!proceedFlag">
                <button mat-button class="back-button" type="button" (click)="onBackBtnClick();trackUserActions({'action':'back'})">< Back</button>
            </div>
            <form [formGroup]="getOcrFormGroup()" (ngSubmit)="submitOcrForm();trackUserActions({'action':'submit'})">
            <div class="main-content-wrapper flex">
                <div class="left-div" [ngClass]="{'left-div-center':kycFlag}">
                    <div *ngFor="let item of uploadedFilesURL" class="id-proof-div" [ngClass]="{'otp-view' : showOtpSectionKYC}">
                        <img *ngIf="!showPdf" [src]="item" class="id-proof-img">
                        <pdf-viewer *ngIf="showPdf" 
                        [src]="item" 
                        [original-size]="false" 
                        [render-text] = "true"
                        style="width: 200px; height: 200px" [ngClass]="{'otp-view' : showOtpSectionKYC}">
                        </pdf-viewer>
                    </div>
                    <div class="id-proof-status flex" [ngClass]="{'id-proof-status-center':kycFlag}">
                        <!-- <div class="id-proof-sec flex">
                            <div class="id-proof-content">ID PROOF NOT PROPERLY UPLOADED</div>
                            <img src="assets/close-red.svg" class="close-red">
                        </div> -->
                        <button mat-button class="rescan-button" type="button" (click)="onBackBtnClick()">Rescan Document</button>
                    </div>
                                        <!-- start   -->
                                        <div class="otp-antc-container" *ngIf="ocrDocCode.value == 'DOC_018' && showOtpSectionKYC && (data.id == 'surrender' && (data.role == 'Life Assured' || data.role == 'Beneficiary'))">
                        
                                            <div class="tnc-otp">
                                                    <div class="otp-sent-msg" *ngIf="(showOtpSectionKYC)">
                                                            OTP is triggered to Beneficiaries registered mobile no. & Email id, Please enter OTP to accept T&C
                                                    </div>
                                                    <div class="otp-timer-div" *ngIf="(showOtpSectionKYC)">
                                                            Your OTP will be valid for <span
                                                                      class="otp-timer">{{hours|number:'2.0-0'}}:{{minutes|number:'2.0-0'}}</span>
                                                                    <a (click)="RegenOTP()" *ngIf="(timerSec==0)"><img src="assets/refresh.png" class="refresh-img">Regenerate OTP</a>
                                                    </div>
                                                    <div class="OTP-generation" >
                                                            <div class="form-field-div">  
                                                                    <div class="form-label">Enter the OTP Received<sup>*</sup></div>
                                                                    <form [formGroup] = "beneficiaryOtpForm">
                                                                            <mat-form-field floatLabel="never" class="otp-field">
                                                                                      <mat-placeholder>*******</mat-placeholder>
                                                                                        <input class="form-field-input" formControlName="otp" decimalNumbersOnly [type]="passwordType" maxlength="6" matInput
                                                                                                    (focusout)="otpFocusOut($event)" autocomplete="off">
                                                                                                 
                                                                                                <div class="cursor-point" matSuffix (click)="showPswd()">
                                                                                                    <img formcontrolName="otp" class="iris-closed" matSuffix src="assets/eye_closed.svg"
                                                                                                      *ngIf="reveal">
                                                                                                    <img formcontrolName="otp" class="iris-open" matSuffix src="assets/eye.png"
                                                                                                      *ngIf="!reveal">
                                                                                            </div>
                                                                                            <mat-error class="error-message" *ngIf="(otpFocus && beneficiaryOtpForm.controls.otp.errors?.required)">
                                                                                                    Please enter OTP.
                                                                                                </mat-error>
                                                                                                <mat-error class="error-message" *ngIf="(otpFocus && beneficiaryOtpForm.controls.otp.errors?.pattern)">
                                                                                                    Must be 6 digits.
                                                                                                </mat-error>                                                                 
                                                                            </mat-form-field>                                                       
                                                                    </form>
                                                            </div>
                                                                               
                                                    </div>
                                            </div>
                                            <div class="adhaar-consent-checkbox left" *ngIf="(ocrDocCode.value ==  'DOC_018') &&  ((data.role == 'Beneficiary' || data.role == 'Life Assured') && showOtpSectionKYC)">
                                                    <form [formGroup]="terms">
                                                            <mat-checkbox color = "primary" class="checkbox-text" (change)="readTnC($event)" disableRipple = "true" formControlName="accept">
                                                                    I agree to <a href="/my-atnc" target="_blank" rel = "noopener noreferrer">Terms and Conditions</a>
                                                            </mat-checkbox>
                                                    </form>
                                            </div> 
                                            <div *ngIf="showOtpSectionKYC && statusMessage" class="otp-error-msg">{{this.statusMessage}}</div>
                                    </div>
                    
                                    <div class="adhaar-consent-checkbox center" *ngIf="(ocrDocCode.value ==  'DOC_018') && (data.role == 'Owner' || !showOtpButton && !showOtpSectionKYC)">
                                            <form [formGroup]="terms">
                                                    <mat-checkbox color = "primary" class="checkbox-text" (change)="readTnC($event)" disableRipple = "true" formControlName="accept">
                                                            I agree to <a href="/my-atnc" target="_blank" rel="noopener noreferrer">Terms and Conditions</a>
                                                    </mat-checkbox>
                                            </form>
                                    </div> 
                                        <!-- end -->
                </div>
                <div *ngIf="!kycFlag" class="right-div">
                    <div [ngClass]="{'notify-div-mobile': showDiv, 'notify-div-mobile-hide': !showDiv}">
                        <div class="notify-div" *ngIf="showDiv">
                            <div class="notify-text">
                                If you make edits to the details scanned from the document,
                                a physical verification would be required.
                            </div>
                            <div class="notify-text-two">
                                This typically takes 24-48 hours
                            </div>
                            <div class="notify-text-three">
                                Do you want to make edits?
                            </div>
                            <div class="notify-button flex">
                                <button mat-button class="cancel-notify" (click)="hideDiv()" type="button">Cancel</button>
                                <button mat-button class="proceed-notify"
                                    (click)="hideDivProceed()" type="button">Proceed</button>
                            </div>
                        </div>
                    </div>
                    <div class="form-field-section">
                        <div class="edit-image">
                            <img src="assets/edit.svg" *ngIf="proceedFlag" [hidden]="showDiv" (click)="displayAddress()">
                        </div>                        
                        <div *ngIf="editNameFlag" class="form-field-div update-form-top">
                            <mat-form-field class="update-id-form-field"
                                [ngClass]="{'update-id-disable-form': proceedFlag}">
                                <label class="form-field-label">First Name<span class="error-two">*</span></label>
                                <input class="form-field-input" type="text" maxlength="60" matInput [readonly]="proceedFlag" formControlName="firstName">
                                <mat-error class="error-message" *ngIf="editNameForm.controls.firstName.errors?.required">Please
                                    enter first name</mat-error>
                                <mat-error class="error-message" *ngIf="editNameForm.controls.firstName.errors?.errorMsg && !editNameForm.controls.firstName.errors?.required">
                                    {{editNameForm.controls.firstName.errors?.errorMsg}}</mat-error>
                            </mat-form-field>
                        </div>
                        <div *ngIf="editNameFlag" class="form-field-div">
                            <mat-form-field class="update-id-form-field"
                                [ngClass]="{'update-id-disable-form': proceedFlag}">
                                <label class="form-field-label">Last Name<span class="error-two">*</span></label>
                                <input class="form-field-input" type="text" maxlength="60" matInput [readonly]="proceedFlag" formControlName="lastName">
                                <mat-error class="error-message" *ngIf="editNameForm.controls.lastName.errors?.required">Please
                                    enter last name</mat-error>
                                <mat-error class="error-message" *ngIf="editNameForm.controls.lastName.errors?.errorMsg && !editNameForm.controls.lastName.errors?.required">
                                    {{editNameForm.controls.lastName.errors?.errorMsg}}</mat-error>
                            </mat-form-field>
                        </div>
                        <div *ngIf="neftFlag" class="form-field-div update-form-top">
                                <mat-form-field class="update-id-form-field" [ngClass]="{'update-id-disable-form': proceedFlag}">
                                    <label class="form-field-label">Account Number<span class="error-two">*</span></label>
                                    <input class="form-field-input" matInput type="tel" decimalNumbersOnly value="{{bankdtlbody.accouno}}"
                                        formControlName="accNumber" maxlength="18" [readonly]="proceedFlag" id="account-number">
                                    <mat-error class="error-message" *ngIf="editNeftForm.get('accNumber').getError('errorMsg')">
                                        {{editNeftForm.get('accNumber').getError('errorMsg')}}
                                    </mat-error>
                                </mat-form-field>        
                            </div>                            
                            <div *ngIf="neftFlag" class="form-field-div">
                                <mat-form-field class="update-id-form-field" [ngClass]="{'update-id-disable-form': proceedFlag}">
                                    <label class="form-field-label">Account Holder Name<span class="error-two">*</span></label>
                                    <input class="form-field-input" matInput type="text" value="{{bankdtlbody.clientname}}" [readonly]="proceedFlag"
                                        formControlName="accName" (focusout)="nameFocusOut($event)">
                                    <mat-error class="error-message" *ngIf="editNeftForm.get('accName').getError('errorMsg')">
                                        {{editNeftForm.get('accName').getError('errorMsg')}}
                                    </mat-error>
                                </mat-form-field>        
                            </div>                            
                            <div *ngIf="neftFlag" class="form-field-div">
                                <mat-form-field class="update-id-form-field">
                                    <label class="form-field-label">Account Type<span class="error-two">*</span></label>
                                    <mat-select panelClass="update-country" [(value)]="chooseType" class="select-field"
                                        floatLabel="never" formControlName="accType">
                                        <mat-option *ngFor="let item of type" [value]="item.code">{{item.label}}</mat-option>
                                    </mat-select>
                                    <mat-error class="error-message" *ngIf="editNeftForm.get('accType').getError('errorMsg')">
                                        {{editNeftForm.get('accType').getError('errorMsg')}}
                                    </mat-error>
                                </mat-form-field>        
                            </div>
                            <div *ngIf="neftFlag" class="form-field-div">
                                    <mat-form-field class="update-id-form-field" [ngClass]="{'update-id-disable-form': proceedFlag}">
                                        <label class="form-field-label">IFSC Code<span class="error-two">*</span></label>
                                        <input matInput type="text" value="{{bankdtlbody.ifsccode}}" class="form-field-input ifscCodeText"
                                            formControlName="ifscCode" maxlength="11" [readonly]="proceedFlag">
                                        <mat-error class="error-message" *ngIf="editNeftForm.get('ifscCode').getError('errorMsg')">
                                            {{editNeftForm.get('ifscCode').getError('errorMsg')}}
                                        </mat-error>
                                    </mat-form-field>            
                                </div>
                            <div *ngIf="neftFlag" class="form-field-div">
                                    <mat-form-field class="update-id-form-field update-id-disable-form">
                                        <label class="form-field-label">Bank Name</label>
                                        <input class="form-field-input" readonly matInput type="text" value={{bankName}} formControlName="bankName">
                                        <mat-error class="error-message" *ngIf="editNeftForm.get('bankName').getError('errorMsg')">
                                            {{editNeftForm.get('bankName').getError('errorMsg')}}
                                        </mat-error>
                                    </mat-form-field>            
                                </div>
                            <div *ngIf="neftFlag" class="form-field-div">
                                <mat-form-field class="update-id-form-field update-id-disable-form">
                                    <label class="form-field-label">Branch Name</label>
                                    <input matInput readonly type="text" class="form-field-input" formControlName="branchName"
                                        value={{branchName}}>
                                    <mat-error class="error-message"
                                        *ngIf="editNeftForm.get('branchName').getError('errorMsg')">
                                        {{editNeftForm.get('branchName').getError('errorMsg')}}
                                    </mat-error>
                                </mat-form-field>        
                            </div>
                    </div>
                    <div class="right-sub-div" *ngIf="!proceedFlag">This typically takes 24-48 hours.</div>
                </div>
            </div>
            <div class="button-div flex" [ngClass]="{'update-id-btn-opacity': showDiv}">
                <button *ngIf="proceedFlag" mat-button class="cancel-button" type="button" (click)="onBackBtnClick();trackUserActions({'action':'back'})">Back</button>
                <!-- <button *ngIf="proceedFlag" mat-button class="submit-button" type="submit" [disabled]="(ocrDocCode.value == 'DOC_018' && !accepted)">Submit</button> -->
                <button *ngIf="proceedFlag && !showOtpButton && !showOtpSectionKYC" mat-button class="submit-button" type="submit" [disabled]="ocrDocCode.value ==  'DOC_018' && !getAccept">Submit</button>
                <button *ngIf="proceedFlag && showOtpButton" mat-button class="submit-button" type="button" (click)="generateOtp()">Send OTP</button>
                <button *ngIf="proceedFlag && !showOtpButton && showOtpSectionKYC" class="submit-button" type="button" mat-flat-button (click)="validateOTP()" [disabled]="!getAccept || !beneficiaryOtpForm.valid">Submit</button>                <button *ngIf="!proceedFlag" mat-button class="cancel-button" type="button" (click)="onCancelBtnClick();trackUserActions({'action':'cancel'})">Cancel</button>
                <button *ngIf="!proceedFlag" mat-button class="submit-button" type="submit">Save Changes</button>
            </div>
        </form>
        </div>
    </div>
</ng-template>

<ng-template #updateNeftDetails>
    <div class="update-file-div">
        <div mat-dialog-actions *ngIf="!data.disableClose" (click)="cancelNeftDetails();trackUserActions({'action':'close'})">
            <div mat-dialog-close class="close-dialog no-mobile" [ngClass]="{'hni-close-dialog':isHNIUSer}">
                Close
                <img src="assets/close.svg">
            </div>
        </div>
        <div class="update-file-header" *ngIf ="namePopup" [ngClass]="{'hni-popups-header':isHNIUSer}">{{bankdtlbody.header}}</div>
        <div class="update-file-header" *ngIf ="!namePopup" [ngClass]="{'hni-popups-header':isHNIUSer}"> Are you sure?</div>
        <div class="update-file-content popup-content-height">
            <form *ngIf ="namePopup" [formGroup]="editNeftForm" (ngSubmit)="submitEditNeftForm();trackUserActions({'action':'submit'})">
                <div class="mandatory"><span class="error-two">*</span>Mandatory Field</div>
                <div class="neft-form-field-section flex">
                    <div class="neft-form-field-div">
                        <mat-form-field class="mailing-form-field">
                            <label class="form-field-label">Account Number<span class="error-two">*</span></label>
                            <input matInput type="tel" decimalNumbersOnly value="{{bankdtlbody.accouno}}" [readonly]= 'readOnlyNeftDtls'
                                formControlName="accNumber" maxlength="18" id="account-number">
                            <mat-error class="error-message" *ngIf="editNeftForm.get('accNumber').getError('errorMsg')">
                                {{editNeftForm.get('accNumber').getError('errorMsg')}}
                            </mat-error>
                        </mat-form-field>
                    </div>                    
                    <div class="neft-form-field-div">
                        <mat-form-field class="mailing-form-field" *ngIf="!pennyDropCase">
                            <label class="form-field-label">Account Holder Name<span class="error-two">*</span></label>
                            <input matInput type="text" value="{{bankdtlbody.clientname}}" class="form-field-input" [readonly]= 'readOnlyNeftDtls'
                                formControlName="accName" (focusout)="nameFocusOut($event)">
                            <mat-error class="error-message" *ngIf="editNeftForm.get('accName').getError('errorMsg')">
                                {{editNeftForm.get('accName').getError('errorMsg')}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="neft-form-field-div">
                        <mat-form-field class="mailing-form-field">
                            <label class="form-field-label">IFSC Code<span class="error-two">*</span></label>
                            <input matInput type="text" value="{{bankdtlbody.ifsccode}}" class="form-field-input ifscCodeText" [readonly]= 'readOnlyNeftDtls'
                                formControlName="ifscCode" maxlength="11">
                            <mat-error class="error-message" *ngIf="editNeftForm.get('ifscCode').getError('errorMsg')">
                                {{editNeftForm.get('ifscCode').getError('errorMsg')}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="neft-form-field-div">
                        <mat-form-field class="mailing-form-field">
                            <label class="form-field-label">Account Type<span class="error-two">*</span></label>
                            <mat-select panelClass="update-country" class="select-field" [disabled]= 'readOnlyNeftDtls' [ngClass]="{'neft-popups-details':readOnlyNeftDtls}"
                                floatLabel="never" formControlName="accType">
                                <mat-option *ngFor="let item of type" [value]="item.code">{{item.label}}</mat-option>
                            </mat-select>
                            <mat-error class="error-message" *ngIf="editNeftForm.get('accType').getError('errorMsg')">
                                {{editNeftForm.get('accType').getError('errorMsg')}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div *ngIf="bankname" class="neft-form-field-div">
                        <mat-form-field class="mailing-form-field">
                            <label class="form-field-label">Bank Name</label> 
                            <!-- [readonly]= 'readOnlyNeftDtls' -->
                            <input readonly matInput type="text" value={{bankName}} readonly formControlName="bankName">
                            <mat-error class="error-message" *ngIf="editNeftForm.get('bankName').getError('errorMsg')">
                                {{editNeftForm.get('bankName').getError('errorMsg')}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div *ngIf="bankname" class="neft-form-field-div">
                        <mat-form-field class="mailing-form-field">
                            <label class="form-field-label">Branch Name</label>
                            <input matInput readonly type="text" class="form-field-input" formControlName="branchName" 
                                value={{branchName}}>
                            <mat-error class="error-message"
                                *ngIf="editNeftForm.get('branchName').getError('errorMsg')">
                                {{editNeftForm.get('branchName').getError('errorMsg')}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div *ngIf="showOtpSection" class="pennydrop-otp-section">
                    <div class="otp-sent-msg">                    
                        OTP sent to your Registered Mobile Number {{mobileNo}} and Email ID {{emailID}}
                    </div>
                    <div class="otp-timer-div">
                        Your OTP will be valid for <span
                            class="otp-timer">{{minutes|number:'2.0-0'}}:{{seconds|number:'2.0-0'}}</span>
                        <a (click)="regenPennyDropOtp()"><img src="assets/refresh.png" class="refresh-img">Regenerate OTP</a>
                    </div>
                    <form [formGroup]="pennyDropOtpForm" (ngSubmit)="submitPennyDropOtpForm()">
                        <div class="neft-form-field-div penny-drop-otp-field">
                            <mat-form-field floatLabel="never" class="mailing-form-field">
                                <label class="form-field-label">OTP<span class="error-two">*</span></label>                                               
                                <input class="form-field-input" formControlName="otp" decimalNumbersOnly type="tel" maxlength="6" matInput
                                    (focusout)="otpFocusOut($event)">
                                <mat-error class="error-message" *ngIf="(otpFocus && otpValidation.otp.errors?.required)">
                                    Please enter OTP.
                                </mat-error>
                                <mat-error class="error-message" *ngIf="(otpFocus && otpValidation.otp.errors?.pattern)">
                                    Must be 6 digits.
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="unit-sr-otp" *ngIf="fetchDetailsbtn" [ngClass] ="{'disables' : ocrFailure}"> <span class="unit-new-sr"  [ngClass] ="{'disables' : ocrFailure}" (click)="fetchDetails()">Fetch Details from document</span></div>
                        <div class="button-div flex">
                            <button mat-button type="button" class="cancel-button" (click)="cancelNeftDetails()">Cancel</button>                   
                            <button mat-button type="submit" class="submit-button">Verify</button>
                        </div>                    
                    </form>
                </div>
                <div *ngIf="!showOtpSection">
                    <div class="unit-sr" *ngIf="fetchDetailsbtn" [ngClass] ="{'disables' : ocrFailure}"> <span class="unit-new-sr"  [ngClass] ="{'disables' : ocrFailure}" (click)="fetchDetails()">Fetch Details from document</span></div>
                </div>
            
                <div *ngIf="!showOtpSection" class="button-div flex">
                    <button *ngIf="data.id == 'surrender'" mat-button class="cancel-button" type="button" (click)="closeNeftDetails(exitNEFTDtls);trackUserActions({'action':'back'})">Back</button>
                    <button *ngIf="data.id != 'surrender'" mat-button class="cancel-button" type="button" (click)="cancelNeftDetails();trackUserActions({'action':'back'})">Back</button>
                    <button *ngIf="!data.isPennyDrop && !hideBtn" mat-button class="submit-button" type="submit">Save Changes</button>
                    <button *ngIf="data.isPennyDrop && !data.existingFlag" mat-button class="submit-button" type="submit">Submit</button>
                    <button *ngIf="data.isPennyDrop && data.existingFlag" mat-button class="submit-button" type="submit">Submit</button>
                    <button *ngIf="hideBtn && data.existingFlag" mat-button class="submit-button" type="button" (click)="existingNeftFn();trackUserActions({'action':'save changes'})">Save Changes</button>
                </div>
            </form>
            <div *ngIf="!namePopup" class="pan-card-details">
                <div class="pan-card-input">
                    <div class="pan-card-content">The name entered does not match with our records.</div>
                    <div class="pan-card-confirmation">
                        Are you sure you want to continue?
                    </div>
                </div>
                <div class="button-div flex">
                    <button mat-button class="cancel-button" type="button" (click)="holdNeft()">No</button>
                    <button mat-button class="submit-button" type="button" (click)="acceptNeft()">Yes</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #exitNEFTDtls>
    <div class="update-file-div">
            <div class="update-file-header" [ngClass]="{'hni-popups-header':isHNIUSer}">Do you want to exit? </div>
    <div class="update-file-content popup-content-height">
        <div class="pan-card-details">
            <div class="pan-card-input">
                <!-- <div class="pan-card-content"></div> -->
                <div class="pan-card-confirmation">
                    If you choose to exit now all the details that you have provided will be lost.
                </div>
            </div>
        <div class="button-div flex">
            <button mat-button class="cancel-button" type="button" (click)="goBackBtn()">Go back</button>
            <button *ngIf="neftFlag && !chequeFlag" mat-button class="submit-button" type="button" (click)="editNEFTDtls(updateNeftDetails)">Continue to edit</button>
            <button *ngIf="!neftFlag && chequeFlag" mat-button class="submit-button" type="button" (click)="editNEFTDtls(updateChequeDetails)">Continue to edit</button>
        </div>
        </div>
    </div>  
    </div>
</ng-template>

<ng-template #updateEcsDetails>
    <div class="update-file-div">
        <div mat-dialog-actions  *ngIf="!data.disableClose" (click)="cancelNeftDetails()">
            <div mat-dialog-close class="close-dialog no-mobile" [ngClass]="{'hni-close-dialog':isHNIUSer}">
                Close
                <img src="assets/close.svg">
            </div>
        </div>
        <div class="update-file-header" [ngClass]="{'hni-popups-header':isHNIUSer}">{{bankdtlbody.header}}</div>
        <div class="update-file-content popup-content-height">
            <form [formGroup]="editEcsForm" (ngSubmit)="submitEditEcsForm()">
                <div class="mandatory"><span class="error-two">*</span>Mandatory Field</div>
                <div class="checkbox-column-name flex">
                    <mat-checkbox (change)="changeName($event)" [disableRipple]=true [checked]=nameDeclaration
                        class="name-declaration-checkbox">
                        Same Name Declaration
                    </mat-checkbox>
                </div>
                <div class="neft-form-field-section flex">
                    <div class="neft-form-field-div">
                        <mat-form-field class="mailing-form-field">
                            <label class="form-field-label">Account Holder Name<span class="error-two">*</span></label>
                            <input matInput type="text" value="{{bankdtlbody.clientname}}" [readonly]="!nameDeclaration"
                                class="form-field-input" formControlName="accName">
                            <mat-error class="error-message" *ngIf="editEcsForm.get('accName').getError('errorMsg')">
                                {{editEcsForm.get('accName').getError('errorMsg')}}
                            </mat-error>
                        </mat-form-field>

                    </div>
                    <div class="neft-form-field-div">
                        <mat-form-field class="mailing-form-field">
                            <label class="form-field-label">Account Type<span class="error-two">*</span></label>
                            <mat-select panelClass="update-country" value="{{bankdtlbody.acctype}}" class="select-field"
                                floatLabel="never" formControlName="accType">
                                <mat-option *ngFor="let item of type" [value]="item">{{item.label}}</mat-option>
                            </mat-select>
                            <mat-error class="error-message" *ngIf="editEcsForm.get('accType').getError('errorMsg')">
                                {{editEcsForm.get('accType').getError('errorMsg')}}
                            </mat-error>
                        </mat-form-field>

                    </div>
                    <div class="neft-form-field-div">
                        <mat-form-field class="mailing-form-field">
                            <label class="form-field-label">Account Number<span class="error-two">*</span></label>
                            <input matInput type="password" autocomplete="new-password" decimalNumbersOnly value="{{bankdtlbody.accouno}}"
                                formControlName="accNumber" maxlength="15" id="account-number">
                            <mat-error class="error-message" *ngIf="editEcsForm.get('accNumber').getError('errorMsg')">
                                {{editEcsForm.get('accNumber').getError('errorMsg')}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="neft-form-field-div">
                        <mat-form-field class="mailing-form-field">
                            <label class="form-field-label">Re-enter Account Number<span
                                    class="error-two">*</span></label>
                            <input matInput type="tel" decimalNumbersOnly value="" formControlName="reAccNumber"
                                maxlength="15" id="account-number">
                            <mat-error class="error-message"
                                *ngIf="editEcsForm.controls['reAccNumber'].errors?.Matchlength">
                                Length must be either 14 or 15
                            </mat-error>
                            <mat-error class="error-message"
                                *ngIf="editEcsForm.controls['reAccNumber'].errors?.MatchAccountNum">
                                Account number doesnot match
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="neft-form-field-div bank-branch-main">
                        <div class="bank-branch-div" (click)="updateFileDiv(bankBranchPopup)">Search by Bank Name and
                            Branch Name</div>
                    </div>
                    <div class="neft-form-field-div">
                        <mat-form-field class="mailing-form-field">
                            <label class="form-field-label">IFSC Code<span class="error-two">*</span></label>
                            <div class="form-field-icon">
                                <input matInput type="text" class="form-field-input" formControlName="ifscCode"
                                    maxlength="11">
                                <md-icon class="fa fa-search" aria-hidden="true" (click)="getBankDetails('ifscCode')">
                                </md-icon>
                            </div>

                            <mat-error class="error-message" *ngIf="editEcsForm.get('ifscCode').getError('errorMsg')">
                                {{editEcsForm.get('ifscCode').getError('errorMsg')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="neft-form-field-div">
                        <mat-form-field class="mailing-form-field">
                            <label class="form-field-label">MICR Code<span class="error-two">*</span></label>
                            <div class="form-field-icon">
                                <input matInput type="tel" decimalNumbersOnly class="form-field-input"
                                    formControlName="micr" maxlength="9">
                                <!-- <mat-icon class="search-icon" matSuffix (click)="getBankDetails('micr')">search</mat-icon> -->
                                <md-icon class="fa fa-search" aria-hidden="true" (click)="getBankDetails('micr')">
                                </md-icon>
                            </div>
                            <mat-error class="error-message" *ngIf="editEcsForm.get('micr').getError('errorMsg')">
                                {{editEcsForm.get('micr').getError('errorMsg')}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="neft-form-field-div">
                        <mat-form-field class="mailing-form-field">
                            <label class="form-field-label">Bank Name<span class="error-two">*</span></label>
                            <input readonly matInput type="text" formControlName="bankName">
                        </mat-form-field>
                        <mat-error class="error-message" *ngIf="editEcsForm.get('bankName').getError('errorMsg')">
                            {{editEcsForm.get('bankName').getError('errorMsg')}}
                        </mat-error>
                    </div>
                    <div class="neft-form-field-div">
                        <mat-form-field class="mailing-form-field">
                            <label class="form-field-label">Branch Name<span class="error-two">*</span></label>
                            <input matInput readonly type="text" class="form-field-input" formControlName="branchName"
                                value="">
                            <mat-error class="error-message" *ngIf="editEcsForm.get('branchName').getError('errorMsg')">
                                {{editEcsForm.get('branchName').getError('errorMsg')}}
                            </mat-error>
                        </mat-form-field>
                    </div>


                    <div class="neft-form-field-div">
                        <mat-form-field class="mailing-form-field">
                            <label class="form-field-label">From Date</label>
                            <input readonly matInput type="text" class="form-field-input" formControlName="fromDate"
                               >
                            <mat-error class="error-message" *ngIf="editEcsForm.controls.fromDate.errors?.required">
                                Please enter Value
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="neft-form-field-div">
                        <mat-form-field class="mailing-form-field">
                            <label class="form-field-label">Amount (INR)</label>
                            <input readonly matInput type="text" class="form-field-input" value=''
                                formControlName="amount">
                            <mat-error class="error-message" *ngIf="editEcsForm.controls.amount.errors?.required">
                                Please enter Value
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="neft-form-field-div">
                        <mat-form-field class="mailing-form-field">
                            <label class="form-field-label">Incremental Premium Nach</label>
                            <input readonly matInput type="text" class="form-field-input" value=''
                                formControlName="incrPremNach">
                            <mat-error class="error-message" *ngIf="editEcsForm.controls.incrPremNach.errors?.required">
                                Please enter Value
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="neft-form-field-div">
                        <mat-form-field class="mailing-form-field">
                            <label class="form-field-label">Preferred Billing Date<span
                                    class="error-two">*</span></label>
                            <mat-select panelClass="update-country" class="select-field" floatLabel="never"
                                formControlName="prefBillDate">
                                <mat-option *ngFor="let item of billingDate" [value]="item">{{item.label}}</mat-option>
                            </mat-select>
                            <mat-error class="error-message"
                                *ngIf="editEcsForm.get('prefBillDate').getError('errorMsg')">
                                {{editEcsForm.get('prefBillDate').getError('errorMsg')}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="button-div flex">
                    <button mat-button class="cancel-button" type="button" (click)="cancelNeftDetails()">Back</button>
                    <button mat-button class="submit-button" type="submit">Save Changes</button>
                </div>
            </form>
        </div>
    </div>
</ng-template>

<ng-template #bankBranchPopup>
    <div class="update-file-div">
        <div mat-dialog-actions  *ngIf="!data.disableClose" (click)="cancelBankDetails()">
            <div mat-dialog-close class="close-dialog no-mobile" [ngClass]="{'hni-close-dialog':isHNIUSer}">
                Close
                <img src="assets/close.svg">
            </div>
        </div>
        <div class="update-file-header" [ngClass]="{'hni-popups-header':isHNIUSer}">Search by Bank/Branch Name</div>
        <div class="update-file-content popup-content-height">
            <form [formGroup]="searchBankBranch">
                <div class="neft-form-field-section flex">
                    <div class="neft-form-field-div bank-name-width">
                        <mat-form-field class="mailing-form-field">
                            <label class="form-field-label">Bank Name:</label>
                            <input matInput type="text" class="form-field-input" value='' formControlName="bankSearch">
                        </mat-form-field>
                    </div>
                    <div class="neft-form-field-div bank-name-width">
                        <mat-form-field class="mailing-form-field">
                            <label class="form-field-label">Branch Name:</label>
                            <input matInput type="text" class="form-field-input" value=''
                                formControlName="branchSearch">
                        </mat-form-field>
                    </div>
                    <div class="button-div flex search-button-width">
                        <button mat-button class="submit-button" type="button" (click)="searchDetails()">Search</button>
                    </div>
                </div>
                <div class="policies-list flex" *ngIf="bankBranchFlag" mat-dialog-close>
                    <mat-card class="policy-doc-card" *ngFor="let item of bankDetails;" (click)="selectBranch(item)">
                        <div class="bank-title">{{item.BANK_NM}}</div>
                        <div class="branch-name">{{item.BRANCH_NM}}</div>
                        <div class="ifsc-code">IFSC CODE: {{item.IFSC_CD}}</div>
                        <div class="micr-number">MICR-Number: {{item.MICR_NO}}</div>
                    </mat-card>

                </div>
            </form>
        </div>
    </div>
</ng-template>

<ng-template #editNamePopup>
    <div class="pan-card-details">
        <div mat-dialog-actions  *ngIf="!data.disableClose" (click)="cancelEditName();trackUserActions({'eventName': 'Edit Name - close'})">
            <div mat-dialog-close class="close-dialog no-mobile" [ngClass]="{'hni-close-dialog':isHNIUSer}">
                Close
                <img src="assets/close.svg">
            </div>
        </div>
        <div class="pan-card-details-header" [ngClass]="{'hni-popups-header':isHNIUSer}">Edit Name</div>
        <div class="pan-card popup-content-height">
            <div class="mandatory-field"><span class="red-star">*</span>Mandatory Field</div>
            <form [formGroup]="editNameForm" (ngSubmit)="submitEditNameForm();trackUserActions({'eventName': 'Edit Name - save changes'})">
                <div class="pan-card-input">
                    <mat-form-field class="pan-form-field">
                        <label class="pan-label">First Name<span class="error-two">*</span></label>
                        <input matInput type="text" maxlength="60" formControlName="firstName">
                        <mat-error class="error-message" *ngIf="editNameForm.controls.firstName.errors?.required">Please
                            enter first name</mat-error>
                        <mat-error class="error-message" *ngIf="editNameForm.controls.firstName.errors?.errorMsg">
                            {{editNameForm.controls.firstName.errors?.errorMsg}}</mat-error>
                    </mat-form-field>
                    <mat-form-field class="pan-form-field">
                        <label class="pan-label">Last Name<span class="error-two">*</span></label>
                        <input matInput type="text" maxlength="60" formControlName="lastName">
                        <mat-error class="error-message" *ngIf="editNameForm.controls.lastName.errors?.required">Please
                            enter last name</mat-error>
                        <mat-error class="error-message" *ngIf="editNameForm.controls.lastName.errors?.errorMsg">
                            {{editNameForm.controls.lastName.errors?.errorMsg}}</mat-error>
                    </mat-form-field>
                    <div class="display-message" [innerHTML]="displayMessage"></div>
                </div>
                <div class="button-div flex">
                    <button *ngIf="!data.disableClose" mat-button class="cancel-button" type="button" (click)="cancelEditName();trackUserActions({'eventName': 'Edit Name - cancel'})">Cancel</button>
                    <button mat-button class="submit-button" type="submit" [disabled]="isNameSubmitDisabled || !editNameForm.valid">Save Changes</button>
                </div>
            </form>
        </div>

    </div>
</ng-template>

<ng-template #updateChequeDetails>
    <div class="update-file-div">
        <div mat-dialog-actions *ngIf="!data.disableClose" (click)="cancelNeftDetails()">
            <div mat-dialog-close class="close-dialog no-mobile" [ngClass]="{'hni-close-dialog':isHNIUSer}">
                Close
                <img src="assets/close.svg">
            </div>
        </div>
        <div class="update-file-header" *ngIf ="namePopup" [ngClass]="{'hni-popups-header':isHNIUSer}">{{bankdtlbody.header}}</div>
        <div class="update-file-header" *ngIf ="!namePopup" [ngClass]="{'hni-popups-header':isHNIUSer}"> Are you sure?</div>
        <div class="update-file-content popup-content-height">
            <form *ngIf ="namePopup" [formGroup]="editChequeForm" (ngSubmit)="submitEditNeftForm()">
                <div class="mandatory"><span class="error-two">*</span>Mandatory Field</div>
                <div class="neft-form-field-section flex">
                    <div class="neft-form-field-div">
                        <mat-form-field class="mailing-form-field">
                            <label class="form-field-label">Account Number<span class="error-two">*</span></label>
                            <input matInput type="tel" decimalNumbersOnly value="{{bankdtlbody.accouno}}" [readonly]= 'readOnlyNeftDtls'
                                formControlName="accNumber" maxlength="18" id="account-number">
                            <mat-error class="error-message" *ngIf="editChequeForm.get('accNumber').getError('errorMsg')">
                                {{editChequeForm.get('accNumber').getError('errorMsg')}}
                            </mat-error>
                        </mat-form-field>
                    </div>                    
                    <div class="neft-form-field-div">
                        <mat-form-field class="mailing-form-field">
                            <label class="form-field-label">Account Holder Name<span class="error-two">*</span></label>
                            <input matInput type="text" value="{{bankdtlbody.clientname}}" class="form-field-input" [readonly]= 'readOnlyNeftDtls'
                                formControlName="accName" (focusout)="nameFocusOut($event)">
                            <mat-error class="error-message" *ngIf="editChequeForm.get('accName').getError('errorMsg')">
                                {{editChequeForm.get('accName').getError('errorMsg')}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="neft-form-field-div">
                        <mat-form-field class="mailing-form-field">
                            <label class="form-field-label">IFSC Code<span class="error-two">*</span></label>
                            <input matInput type="text" value="{{bankdtlbody.ifsccode}}" class="form-field-input ifscCodeText" [readonly]= 'readOnlyNeftDtls'
                                formControlName="ifscCode" maxlength="11">
                            <mat-error class="error-message" *ngIf="editChequeForm.get('ifscCode').getError('errorMsg')">
                                {{editChequeForm.get('ifscCode').getError('errorMsg')}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="neft-form-field-div">
                        <mat-form-field class="mailing-form-field">
                            <label class="form-field-label">Account Type<span class="error-two">*</span></label>
                            <mat-select panelClass="update-country" class="select-field" [disabled]= 'readOnlyNeftDtls' [ngClass]="{'neft-popups-details':readOnlyNeftDtls}"
                                floatLabel="never" formControlName="accType">
                                <mat-option *ngFor="let item of type" [value]="item.code">{{item.label}}</mat-option>
                            </mat-select>
                            <mat-error class="error-message" *ngIf="editChequeForm.get('accType').getError('errorMsg')">
                                {{editChequeForm.get('accType').getError('errorMsg')}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <!-- <div *ngIf= "type !='CHEQUE'" class="neft-form-field-div">
                        <mat-form-field class="mailing-form-field">
                            <label class="form-field-label">Bank Name</label>
                            <input readonly matInput type="text" value={{bankName}} [readonly]= 'readOnlyNeftDtls' formControlName="bankName">
                            <mat-error class="error-message" *ngIf="editChequeForm.get('bankName').getError('errorMsg')">
                                {{editChequeForm.get('bankName').getError('errorMsg')}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div *ngIf= "type !='CHEQUE'" class="neft-form-field-div">
                        <mat-form-field class="mailing-form-field">
                            <label class="form-field-label">Branch Name</label>
                            <input matInput readonly type="text" class="form-field-input" formControlName="branchName" [readonly]= 'readOnlyNeftDtls'
                                value={{branchName}}>
                            <mat-error class="error-message"
                                *ngIf="editChequeForm.get('branchName').getError('errorMsg')">
                                {{editChequeForm.get('branchName').getError('errorMsg')}}
                            </mat-error>
                        </mat-form-field>
                    </div> -->
                </div>
                <!-- <div *ngIf="showOtpSection" class="pennydrop-otp-section">
                    <div class="otp-sent-msg">                    
                        OTP sent to your Registered Mobile Number {{mobileNo}} and Email ID {{emailID}}
                    </div>
                    <div class="otp-timer-div">
                        Your OTP will be valid for <span
                            class="otp-timer">{{minutes|number:'2.0-0'}}:{{seconds|number:'2.0-0'}}</span>
                        <a (click)="regenPennyDropOtp()"><img src="assets/refresh.png" class="refresh-img">Regenerate OTP</a>
                    </div>
                    <form [formGroup]="pennyDropOtpForm" (ngSubmit)="submitPennyDropOtpForm()">
                        <div class="neft-form-field-div penny-drop-otp-field">
                            <mat-form-field floatLabel="never" class="mailing-form-field">
                                <label class="form-field-label">OTP<span class="error-two">*</span></label>                                               
                                <input class="form-field-input" formControlName="otp" decimalNumbersOnly type="tel" maxlength="6" matInput
                                    (focusout)="otpFocusOut($event)">
                                <mat-error class="error-message" *ngIf="(otpFocus && otpValidation.otp.errors?.required)">
                                    Please enter OTP.
                                </mat-error>
                                <mat-error class="error-message" *ngIf="(otpFocus && otpValidation.otp.errors?.pattern)">
                                    Must be 6 digits.
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="button-div flex">
                            <button mat-button type="button" class="cancel-button" (click)="cancelNeftDetails()">Cancel</button>                   
                            <button mat-button type="submit" class="submit-button">Verify</button>
                        </div>                    
                    </form>
                </div> -->
                <div class="checkbox-column-name flex">
                    <mat-checkbox formControlName="checkBoxValue" [disableRipple]=true [ngModel]=IsChecked [disabled]=chequeFlag
                        class="name-declaration-checkbox">
                        Cheque
                    </mat-checkbox>
                </div>
                <div *ngIf="!showOtpSection" class="button-div flex">
                    <button mat-button class="cancel-button" type="button" (click)="cancelNeftDetails()">Back</button>
                    <button *ngIf="!data.isPennyDrop && !readOnlyNeftDtls" mat-button class="submit-button" type="submit">Save Changes</button>
                    <button *ngIf="data.isPennyDrop" mat-button class="submit-button" type="submit">Submit</button>
                    <button *ngIf="readOnlyNeftDtls" mat-button class="submit-button" type="button" (click)="existingNeftFn()">Save Changes</button>
                </div>
            </form>
            <div *ngIf="!namePopup" class="pan-card-details">
                <div class="pan-card-input">
                    <div class="pan-card-content">The name entered does not match with our records.</div>
                    <div class="pan-card-confirmation">
                        Are you sure you want to continue?
                    </div>
                </div>
                <div class="button-div flex">
                    <button mat-button class="cancel-button" type="button" (click)="holdNeft()">No</button>
                    <button mat-button class="submit-button" type="button" (click)="acceptNeft()">Yes</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #srSubmitpopup>
    <div class="update-file-div">
        <div mat-dialog-actions *ngIf="!data.disableClose" (click)="cancelNeftDetails()">
            <div mat-dialog-close class="close-dialog no-mobile" [ngClass]="{'hni-close-dialog':isHNIUSer}">
                Close
                <img src="assets/close.svg">
            </div>
        </div>
        <div class="update-file-header" [ngClass]="{'hni-popups-header':isHNIUSer}">Message</div>
        <div class="pan-card-details">
        <div class="popup-content flex">
                <div class="green-tick-div flex">
                        <img src="assets/green-tick.svg">
                </div>
                Service Request No. {{srNo}} has been created successfully. To track it, please visit the service request section of my account portal.           
        </div>
        <div class="popup-confirmation flex">
                <div class="info-active flex">
                        <img src="assets/info_active.svg">
                </div>Would you also like to </div>
    </div>
        <div class="button-div flex">
                <button matButton *ngIf="!KycBtn" class="update-button" (click)="updateKYC()">Update KYC</button>
                <button matButton *ngIf="!PanBtn" class="update-button" (click)="updatePAN()">Update PAN</button>
                <button matButton *ngIf="!NeftBtn" class="update-button" (click)="updateNEFT()">Update NEFT</button>
            </div>
    </div>
</ng-template>