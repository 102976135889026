<div class="death-claim-page">
    <div class="custom-link-page-padding">
        <div class="custom-link-page-split flex">
            <div class="custom-link-left-div">
                <mat-card class="custom-link-left-section">                    
                    <div>                        
                        <div class="login-title">Individual Death Claim Form</div>
                        <div class="sr-details-options">
                            <div class="sr-details-header">
                                Section A
                            </div>
                            <div class="sub-header">
                                Policy Details
                            </div>
                            <div class="sr-details-content flex">
                                <ng-container *ngFor="let field of staticFieldText.sectionA.policyDtls.fields" class="sr-detail-title sr-detail-text">
                                    <div class="sr-details-field">
                                        <ng-container [ngTemplateOutlet]="inputIterator"
                                            [ngTemplateOutletContext]="{field: field}">
                                        </ng-container>
                                    </div>
                                </ng-container>
                                <!-- <div class="sr-details-field" *ngIf="deathClaimDtls && deathClaimDtls['SML_POLICY']">
                                    <div class="sr-detail-text">
                                        {{deathClaimDtls.SML_POLICY}}
                                    </div>
                                </div> -->
                            </div>
                        </div>
                        <div class="sr-details-options">
                            <div class="sr-details-header">
                                Section B
                            </div>
                            <div class="sub-header">
                                Details of Life Assured (LA)
                            </div>
                            <div class="sr-details-content flex">
                                <ng-container *ngFor="let field of staticFieldText.sectionB.LA.fields" class="sr-detail-title sr-detail-text">
                                    <div class="sr-details-field ">
                                        <ng-container [ngTemplateOutlet]="inputIterator"
                                            [ngTemplateOutletContext]="{field: field}">
                                        </ng-container>
                                    </div>
                                </ng-container>                                
                            </div>
                            <div class="sub-header">
                                Details of Family Doctor
                            </div>
                            <div class="sr-details-content flex">
                                <ng-container *ngFor="let field of staticFieldText.sectionB.familyDoctor.fields" class="sr-detail-title sr-detail-text">
                                    <div class="sr-details-field ">
                                        <ng-container [ngTemplateOutlet]="inputIterator"
                                            [ngTemplateOutletContext]="{field: field}">
                                        </ng-container>
                                    </div>
                                </ng-container>
                            </div>
                            <div class="sub-header">
                                Details of Last Treated / Attended Doctor
                            </div>
                            <div class="sr-details-content flex">
                                <ng-container *ngFor="let field of staticFieldText.sectionB.lastDoctor.fields" class="sr-detail-title sr-detail-text">
                                    <div class="sr-details-field ">
                                        <ng-container [ngTemplateOutlet]="inputIterator"
                                            [ngTemplateOutletContext]="{field: field}">
                                        </ng-container>
                                    </div>
                                </ng-container>
                            </div>
                            <div class="sub-header">
                                Details of Last Employer
                            </div>
                            <div class="sr-details-content flex">
                                <ng-container *ngFor="let field of staticFieldText.sectionB.employer.fields" class="sr-detail-title sr-detail-text">
                                    <div class="sr-details-field ">
                                        <ng-container [ngTemplateOutlet]="inputIterator"
                                            [ngTemplateOutletContext]="{field: field}">
                                        </ng-container>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                        <div class="sr-details-options">
                            <div class="sr-details-header">
                                Nature of Illness and Habit of the Insured
                            </div>
                            <div class="sr-details-content flex">
                                <ng-container *ngFor="let field of staticFieldText.illness.fields" class="sr-detail-title sr-detail-text">
                                    <div class="sr-details-field ">
                                        <ng-container [ngTemplateOutlet]="inputIterator"
                                            [ngTemplateOutletContext]="{field: field}">
                                        </ng-container>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                        <div class="sr-details-options">
                            <div class="sr-details-header">
                                Other Insurance Details: (Life/Mediclaim/Health)
                            </div>
                            <div class="sr-details-content flex">
                                <div class="table-wrapper">
                                    <table *ngIf="otherInsurance" mat-table [dataSource]="otherInsurance" class="funds-allocation-table">
                                        <ng-container matColumnDef="policy">
                                            <th mat-header-cell *matHeaderCellDef>Policy No.</th>
                                            <td mat-cell *matCellDef="let element">{{element.POLICY_NO}}</td>
                                        </ng-container>
                                        <ng-container matColumnDef="company">
                                            <th mat-header-cell *matHeaderCellDef>Company Name</th>
                                            <td mat-cell *matCellDef="let element">
                                                {{element.COMPANY_NAME}}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="sum">
                                            <th mat-header-cell *matHeaderCellDef>Sum Assured</th>
                                            <td mat-cell *matCellDef="let element">
                                                {{element.SUM_ASSURED}}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="status">
                                            <th mat-header-cell *matHeaderCellDef>Status</th>
                                            <td mat-cell *matCellDef="let element">
                                                {{element.STATUS}}
                                            </td>
                                        </ng-container>                                        
                                        <tr mat-header-row *matHeaderRowDef="columns"></tr>
                                        <tr mat-row *matRowDef="let row;columns: columns;"></tr>
                                    </table>
                                    <div *ngIf="!otherInsurance">No Records Found</div>
                                </div>                                    
                            </div>
                        </div>
                        <div class="sr-details-options">
                            <div class="sr-details-header">
                                Details of Claimant
                            </div>
                            <div class="sr-details-content flex">
                                <ng-container *ngFor="let field of staticFieldText.claimantDtls.fields" class="sr-detail-title sr-detail-text">
                                    <div class="sr-details-field ">
                                        <ng-container [ngTemplateOutlet]="inputIterator"
                                            [ngTemplateOutletContext]="{field: field}">
                                        </ng-container>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                        <div class="sr-details-options">
                            <div class="sr-details-header">
                                Claimant NEFT Mandate / Bank Account Details
                            </div>
                            <div class="sr-details-content flex">
                                <ng-container *ngFor="let field of staticFieldText.claimantBank.fields" class="sr-detail-title sr-detail-text">
                                    <div class="sr-details-field ">
                                        <ng-container [ngTemplateOutlet]="inputIterator"
                                            [ngTemplateOutletContext]="{field: field}">
                                        </ng-container>
                                    </div>
                                </ng-container>
                            </div>
                            <div class="sr-details-content annuity-checkbox-content">
                                <div class="sr-detail-title">Mandatory for Pension Plans, Please indicate how you would like to receive the benefits
                                </div>
                                <div class="annuity-checkbox-div flex">
                                    <mat-checkbox color="primary" class="annuity-checkbox" *ngFor="let item of annuityCheckbox" disabled [checked]="item.isChecked">
                                        <div class="sr-detail-text">{{item.label}}</div>
                                    </mat-checkbox>
                                </div>
                            </div>
                        </div>
                        <div class="sr-details-options">
                            <div class="sr-details-header">
                                Section C
                            </div>
                            <div class="sub-header">
                                Declaration and Authorisation
                            </div>
                            <div class="sr-details-content flex">
                                <div class="instruction-section">
                                    <div class="list-item flex" *ngFor="let item of declarations">
                                        <img src="assets/li-style.svg" class="list-item-img">
                                        <span>{{item}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="checkbox">
                            <mat-checkbox [formControl]="consentCheckbox" color="primary" class="consent-checkbox">
                                <div class="confirm-msg">I hereby give my consent for Claims form & conducting checks and obtaining documents for Death Claim</div>
                            </mat-checkbox>
                        </div>
                        <div class="button-div flex">
                            <button mat-button class="cancel button" (click)="onReject()">Reject</button>
                            <button mat-button class="submit button" [disabled]="!consentCheckbox.value" (click)="onApprove()">Approve</button>
                        </div>                        
                    </div>
                </mat-card>
            </div>
        </div>
    </div>
</div>
<ng-template let-field="field" #inputIterator>
    <div class="sr-detail-title">
        {{field.label}}
    </div>
    <div class="sr-detail-text" *ngIf="deathClaimDtls && deathClaimDtls[field.code] !== null && deathClaimDtls[field.code] !== '' && deathClaimDtls[field.code] !== undefined; else showNA">
       <span  [ngClass]="{'text-upper-case': field.upperCase }">{{deathClaimDtls[field.code]}}</span> 
    </div>
    <ng-template #showNA>
        NA
    </ng-template>
</ng-template>
<ng-template #reasonPopup>
    <div class="reason-popup">
        <div mat-dialog-actions class="mat-dialog-actions" (click)="closeAllPopup()">
            <div mat-dialog-close class="close-dialog" [ngClass]="{'hni-close-dialog':isHNIUSer}">
                Close
                <img src="assets/close.svg">
            </div>
        </div>
        <div class="reason-popup-header flex" [ngClass]="{'hni-get-touch':isHNIUSer}">
            <div>Reason</div>
        </div>
        <div class="reason-popup-content popup-content-height">
            <div class="reject-reason-form-div">
                <mat-form-field class="reject-reason-form-field">
                    <label class="form-field-label">Reason for rejection<sup>*</sup></label>
                    <mat-select panelClass="reject-reason" [formControl]="reason" disableRipple
                        disableOptionCentering>
                        <mat-option *ngFor="let item of rejectReasons" [value]="item.label">{{item.label}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>            
            <div class="reason-popup-btns flex">
                <button class="submit-btn fixed-btn-width" (click)="onReasonSubmit()">Submit</button>                    
            </div>
        </div>            
    </div>
</ng-template>

