import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/services/http/http.service';
import { MyPoliciesService } from 'src/app/services/my-policies/my-policies.service';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { CreateSRService } from 'src/app/services/create-sr/create-s-r.service';
import { DataService } from 'src/app/services/data/data.service';
import { AuthService } from 'src/app/services/auth/auth.service';



@Component({
  selector: 'app-payment-redirect',
  templateUrl: './payment-redirect.component.html',
  styleUrls: ['./payment-redirect.component.css']
})
export class PaymentRedirectComponent implements OnInit {
  searchPaymentDetails: any;
  srType: string;
  srTypeArray: any = [];
  txnid: string;
  status: string;
  index: number;
  srNo: string;
  position: void;
  digitalPaymentReqBody: any;

  constructor(public _httpService: HttpService, public myPoliciesService: MyPoliciesService, public router: Router,
    public createSrService: CreateSRService, public auth: AuthService, public route: ActivatedRoute, public dataService: DataService) { }

  ngOnInit(): void {
    //this.searchDigitalPaymentDtls();
    this.route.queryParamMap.subscribe((params: any) => {
      let paramValue = { ...params }
      if (paramValue != undefined && paramValue != null && paramValue != {}) {
        this.txnid = this.route.snapshot.queryParamMap.get('txnid');
        this.srNo = this.route.snapshot.queryParamMap.get('srnum');
        this.status = this.route.snapshot.queryParamMap.get('status');
      }
      if (this.txnid || this.srNo) {
        setTimeout(() => this.searchDigitalPaymentDtls(), 0)
      }
    })

  }
  searchDigitalPaymentDtls() {
    if (this.srNo) {
      this.digitalPaymentReqBody = {
        srno: this.srNo
      };
    }
    else {
      this.digitalPaymentReqBody = {
        paymenttxnid: this.txnid
      };
    }
    return this.myPoliciesService.searchDigiPay(this.digitalPaymentReqBody).subscribe((resp) => {
      if (this._httpService.isSuccessResponse(resp)) {
        this.searchPaymentDetails = resp.body;
        if (this.searchPaymentDetails.premiumtype == "SRBO") {
          this.srType = "SUB_ACT_REVIVAL"
          this.router.navigate(['/customer-service', 'service-request-details']);
        }
        else if (this.searchPaymentDetails.premiumtype == "TPBO") {
          this.srType = "TOPUP"
          this.router.navigate(['/customer-service', 'service-requests']);
        }
        else if (this.searchPaymentDetails.premiumtype == "WCRNP") {
          this.srType = "Renewal";
          this.myPoliciesService.getPolicyDetails().subscribe(dtls => {
            let idx = dtls.findIndex(i => i.POLICYNO.replace(/\s+/g, '') == this.searchPaymentDetails.policyno);
            this.dataService.setRedirectValue(this.searchPaymentDetails.policyno);;
          });
          this.router.navigate(['/my-downloads', 'premium-receipts']);
        }
        else if (this.searchPaymentDetails.premiumtype == "ENACH") {
          this.srType = "CHNG_IN_MODE"
          if (this.status.includes('fail')) {
            this.router.navigate(['/customer-service', 'service-request-details']);
          }
          else if (this.status.includes('success')) {
            this.myPoliciesService.getPolicyDetails().subscribe(dtls => {
              let idx = dtls.findIndex(i => i.POLICYNO.replace(/\s+/g, '') === this.searchPaymentDetails.policyno);
              this.dataService.setCarouselPosition(idx);
            });
            this.router.navigate(['/my-policies', 'policy-details']);
          }
        }
        this.srTypeArray = [{ "srNum": this.searchPaymentDetails.srno, "srType": this.srType }];
        this.createSrService.setSRTypeResp(this.srTypeArray);
      } else {
        this._httpService.apiErrorHandler(resp);
        this.auth.logout('A');
      }
    }),
      catchError((error) => throwError(error));
  }
}
