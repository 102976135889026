import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guards/auth/auth.guard';
import { PaymentRedirectComponent } from './modules/shared/components/payment-redirect/payment-redirect.component';
import { CpadminPortalComponent } from './modules/cpadmin-portal/cpadmin-portal.component';
import { OfflineAadharRedirectComponent } from './modules/shared/components/offline-aadhar-redirect/offline-aadhar-redirect.component';
import { DocUploadRedirectComponent } from './modules/shared/components/doc-upload-redirect/doc-upload-redirect.component';
import { VirtualBranchRedirectComponent } from './modules/shared/components/virtual-branch-redirect/virtual-branch-redirect.component';
import { LoginComponent } from './modules/login/login/login.component';
import { CustomLinkProxyComponent } from './modules/custom-link/custom-link-proxy/custom-link-proxy.component';
import { SsoRedirectComponent } from './modules/shared/components/sso-redirect/sso-redirect.component';
import { TermsAndConditionsComponent } from './modules/shared/components/terms-and-conditions/terms-and-conditions.component';
import { SearchProxyComponent } from './modules/shared/components/search-proxy/search-proxy.component';
import { loanTermsComponent } from './modules/shared/components/loans-terms-and-conditions/loans-terms-and-conditions';

const routes: Routes = [
  { path: 'cpadmin',component: CpadminPortalComponent},
  { path: 'login', loadChildren: () => import("./modules/login/login.module").then(m => m.LoginModule), canActivate: [AuthGuard] },
  { path: 'my-policies', loadChildren: () => import("./modules/my-policies/my-policies.module").then(m => m.MyPoliciesModule), canActivate: [AuthGuard] },
  { path: 'my-account', loadChildren: () => import("./modules/my-account/my-account.module").then(m => m.MyAccountModule), canActivate: [AuthGuard] },
  { path: 'my-downloads', loadChildren: () => import("./modules/my-downloads/my-downloads.module").then(m => m.MyDownloadsModule), canActivate: [AuthGuard] },
  { path: 'customer-service', loadChildren: () => import("./modules/customer-service/customer-service.module").then(m => m.CustomerServiceModule), canActivate: [AuthGuard] },
  { path: 'my-payment',component:PaymentRedirectComponent, canActivate: [AuthGuard] },
  { path: 'my-kyc', component: OfflineAadharRedirectComponent, canActivate: [AuthGuard] },
  { path: 'my-doc-upload', component: DocUploadRedirectComponent },
  { path: 'my-generic-upload', component: DocUploadRedirectComponent },
  { path: 'myFrame', component: VirtualBranchRedirectComponent },
  { path: 'investigator', loadChildren: () => import("./modules/investigator/investigator.module").then(m => m.InvestigatorModule)},
  //{ path: 'my-login' , component: LoginComponent},
  { path: 'my-link', loadChildren: () => import("./modules/custom-link/custom-link.module").then(m => m.CustomLinkModule)},

  { path: 'my-sso' , component: SsoRedirectComponent },

  { path : 'my-atnc', component : TermsAndConditionsComponent},
  { path : 'my-loan-tnc', component : loanTermsComponent},


  //short urls
  { path: 'gu/:_token', component: CustomLinkProxyComponent },
  { path: 'gu', component: CustomLinkProxyComponent },
  { path: 'dc', component: CustomLinkProxyComponent },
  { path: 'dp', component: CustomLinkProxyComponent },
  //{ path: 'dcq/:_token', component: CustomLinkProxyComponent },
  //{ path: 'dcq', component: CustomLinkProxyComponent },
  { path: 'sr', component: CustomLinkProxyComponent },
  { path: 'sr/:_token', component: CustomLinkProxyComponent },
  { path: 'sl/:_token', component: CustomLinkProxyComponent },
  //{ path: 'sl', component: CustomLinkProxyComponent },
  //{ path: 'LookInPage', component: CustomLinkProxyComponent },
  { path: 'my-consent/:_token', component: CustomLinkProxyComponent },
  { path: 'AgentDocUp', component: CustomLinkProxyComponent },
  { path: 'my-atnc/:_token', component: TermsAndConditionsComponent },
  //{ path: 'my-dc/:_token', component: CustomLinkProxyComponent },
  //{ path: 'my-dcf/:_token', component: CustomLinkProxyComponent },
  { path: 'my-search', component: SearchProxyComponent },
  { path: 'my-dc', component: CustomLinkProxyComponent },
  { path: 'my-dcf', component: CustomLinkProxyComponent },
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: '**', redirectTo: '/my-policies', pathMatch: 'full' },
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: "top" })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
