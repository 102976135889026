<div *ngIf="showPage" class="custom-link-page">
    <div class="custom-link-page-padding">
        <div class="custom-link-page-split flex">
            <div class="custom-link-left-div">
                <mat-card class="custom-link-left-section">
                    <div class="add-goback" (click)="backBtn()" *ngIf="qForms">
                        <ul>
                            <!-- <li><a><img src="/assets/home.svg"></a></li> -->
                            <li><a class="back-click">Back</a></li>
                        </ul>
                    </div>
                    <mat-card-title class="login-title">Login</mat-card-title>
                    <app-policy-based-login (loginSuccess)="onLoginSuccess()" [isFormReset]="isFormReset" [loginSource]="loginSource" [policyDtls]="policyDtls" [readOnly]="readOnly">
                    </app-policy-based-login>
                </mat-card>
            </div>
            <div class="login-right-div">
                <mat-card class="login-right-section">
                    <div class="connect-head">
                        Now chat with our official
                        <span>WhatsApp bot</span> Etty in your preferred language!
                    </div>
                    <div class="language-list">
                        <span class="language-title">Languages available are</span>
                        <ul class="languages flex-center">
                            <li>
                                <a>English</a>
                            </li>
                            <li>
                                <a>Hindi</a>
                            </li>
                            <li>
                                <a>Bengali</a>
                            </li>
                            <li>
                                <a>Telugu</a>
                            </li>
                            <li>
                                <a>Tamil</a>
                            </li>
                            <li>
                                <a>Marathi</a>
                            </li>
                            <li>
                                <a>Gujarati</a>
                            </li>
                            <li>
                                <a>Punjabi</a>
                            </li>
                            <li>
                                <a>Malayalam</a>
                            </li>
                            <li>
                                <a>Kannada</a>
                            </li>
                        </ul>
                    </div>
                    <div class="whatsapp-info flex-center">
                        <div class="no-mobile">
                            <div class="query-icon">
                                <img src="assets/customer_care.svg" alt="Customer Care">
                            </div>
                        </div>
                        <div class="query-details">
                            <div class="query-details-text">
                                WhatsApp your policy queries by
                                simply texting a <span>"Hi"</span> on
                            </div>
                            <div class="whatsapp-number flex" (click)="onBtnClick()">
                                <a>+91 82918 90569</a>
                                <img src="assets/whatsapp_white.svg">
                            </div>
                        </div>
                        <img class="qr-code" src="assets/QR_code.png" (click)="onBtnClick()">
                    </div>
                    <div class="useful-links">
                        <div class="flex useful-title-div">
                            <div class="useful-links-title">USEFUL LINKS</div>
                            <img class="useful-link-img" src="assets/link.svg">
                        </div>
                        <div class="useful-links-list-div">
                            <div class="useful-links-list" *ngFor="let item of redirectLinks">
                                <div class="link-text" *ngFor="let subitem of item.links"><a href={{subitem.link}}
                                        target="_blank" rel="noopener noreferrer">{{subitem.label}}</a></div>
                            </div>
                        </div>
                    </div>
                </mat-card>
            </div>
        </div>
        <!-- <mat-card class="login-bottom flex">
                <div class="safety-details flex-center">
                    <div class="safety-image">
                        <img src="assets/safety_image.png">
                    </div>
                    <div class="safety-content">
                        <div class='safety-span'><span class="strong-red">YOUR SAFETY</span> IS OUR FIRST PRIORITY</div>
                        <div class="safety-span-two">Access our digital self-serve options 24/7 at your convenience
                        </div>
                        <a href="assets/static-cp-cms/go-digital-today.html" target="_blank" rel="noopener noreferrer">
                            <button matButton class="knowmore-button">Know More<img class="knowmore-button-image"
                                    src="assets/angle_down_red_sml.svg"></button></a>
                    </div>
                </div>
                <span class="fill-remaining-space"></span>
                    <div class="faq-details">
                      <div class="faq-content">
                        FAQs for HDFC Life Customers during the COVID-19 pandemic
                      </div>
                        <button matButton class="knowmore-button" (click)="downloadCovidPdf()">Download &#8250;</button>
                    </div>
        </mat-card> -->
    </div>
</div>
<div *ngIf="qFormsPost && qForms">
    <app-post-form [qFormDtls]="qFormDtls"></app-post-form></div>