import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router,ActivatedRoute } from '@angular/router';
import { Utils } from 'src/app/utils/common-functions';
import { DataService } from 'src/app/services/data/data.service';
import { deepLinkRouteMap } from 'src/app/utils/deeplink-route-map';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  nonLoggedInPaths: Array<string> = [
    "/login",
    "/login/user-registration",
    "/login/forgot-password"    
  ];
  virtualBranchPathMap = {
    "CHNG_CON_NUM": ["/my-account"],
    "CHNG_NAME": ["/my-account"],
    "UPD_KYC": ["/my-account"],
    "PAN_CARD_UPD": ["/my-account"],
    "STMNT": [
      "/my-downloads",
      "/my-downloads/policy-document", 
      "/my-downloads/unit-statements", 
      "/my-downloads/form-d02",
      "/my-downloads/premium-receipts", 
      "/my-downloads/tds-certificate", 
      "/my-downloads/annual-premium-statements",
      "/my-downloads/bonus-statements", 
      "/my-downloads/payment-receipts", 
      "/my-downloads/medical-reports", 
      "/my-downloads/premium-notice",
      "/my-downloads/maturity-letter",
    ],
    "CHNG_ADDRESS_COMM": ["/my-account"],
    "CHNG_ADDRESS_PERM": ["/my-account"],
    "FUND_SWT": ["/my-policies/switch-funds", "/my-policies/premium-redirection"],
    "PREMIUM_REDRN": ["/my-policies/premium-redirection"],
    "MAT_CLAIM": ["/my-policies/policy-details", "/my-policies/claim"],
    "cp_SurrenderPage": ["/my-link/surrender"],
    "STND_NEFT": ["/my-link/update-bank-details"],
    "cp_unsecuredocUploadPage": ["/my-link/upload","/my-policies/covid", "/my-policies/phs", "/my-policies/short-phs", "/my-policies/smq","/my-policies/nri"],
    "consent": ["/my-link/digital-consent"],
    "cp_unsecuredChequePickUpPage": ["/my-link/cheque-pickup"],
    "COVID": ["/my-policies/covid", "/my-policies/phs", "/my-policies/short-phs", "/my-policies/smq"],
    "PHS": ["/my-policies/covid", "/my-policies/phs", "/my-policies/short-phs", "/my-policies/smq"],
    "COVID_EXIDE": ["/my-policies/covid", "/my-policies/phs", "/my-policies/short-phs", "/my-policies/smq"],
    "SA": ["/surrender-aversion/video", "/surrender-aversion/reason", "/surrender-aversion/policy-details", "/surrender-aversion/v-serve","/surrender-aversion/ppr","/surrender-aversion/intranet-ppr"],
    "SUR_INVEST": ["/investigator/investigator-fr"],
    "UNCLAIM_AMT": ["/my-policies/policy-details"],
    "SUSPENSE_AMT": ["/my-policies/policy-details"],
  }
  constructor(private _utils: Utils, private router: Router, public dataService: DataService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const isAuthenticated = this._utils.isUserAuthenticated();
    if (isAuthenticated) {
      if (this._utils.getExpiredFlag()) {
        localStorage.clear();
        this.router.navigate(['/login']);
      }
      else if (!this.nonLoggedInPaths.includes(state.url)) {
        return true;
      }
      else {
        this.router.navigate(["/my-policies"]);
        return false;
      }
    }
    else if (!isAuthenticated && this.dataService.getIsVirtualBranch()) {
      let data = this.dataService.getVirtualBranchDtls();
      if (data && data.action && this.virtualBranchPathMap[data.action] && this.virtualBranchPathMap[data.action].includes(state.url)) {
        return true;
      } else {
        this.router.navigate(["/login"]);
        return false;
      }      
    } else if (!isAuthenticated && this._utils.getLocalData("source", false) == "CL") {
      let data = {action: this._utils.getLocalData("action", false)}
      if (data && data.action && this.virtualBranchPathMap[data.action] && this.virtualBranchPathMap[data.action].includes(state.url.split('?')[0]) || (this.nonLoggedInPaths.includes(state.url.split('?')[0]))) {
        return true;
      } else {
        if (data.action == 'SA') {
          this.router.navigate(["/surrender-aversion"]);
        } else if (data.action == 'SUR_INVEST') {
          this.router.navigate(["/investigator"]);
        } else {
        this.router.navigate(deepLinkRouteMap[data.action]["path"], { queryParamsHandling: "preserve" });
        }
        return false;
      }      
    } else {
      if (this.nonLoggedInPaths.includes(state.url) || state.url == "/login/reset-password" || state.url.includes('/my-link?')) {
        return true;
      } else {        
        this.router.navigate(["/login"]);
        return false;
      }
    }
  }

}
