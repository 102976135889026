import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { CustomLinkService } from 'src/app/services/custom-link/custom-link.service';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DataService } from 'src/app/services/data/data.service';
import { HttpService } from 'src/app/services/http/http.service';
import { MessageService } from 'src/app/services/message/message.service';
import { ActivatedRoute } from '@angular/router';
import { Utils } from 'src/app/utils/common-functions';

@Component({
  selector: 'app-death-claim',
  templateUrl: './death-claim.component.html',
  styleUrls: ['./death-claim.component.css']
})
export class DeathClaimComponent implements OnInit {
  
  staticFieldText = {
    sectionA: {
      policyDtls: {
        fields: [
          {
            label: "Policy No.(s)",
            code: "SEC_A_POLICY_NO",
            upperCase: false
          },
          {
            label: "Other Policy No.(s)",
            code: "SML_POLICY",
            upperCase: false
          }
        ]
      }
    },
    sectionB: {
      LA: {
        fields: [
          {
            label: "Name of Life Assured",
            code: "SEC_B_NAME_OF_LA",
            upperCase: true
          },
          {
            label: "Father's Name",
            code: "SEC_B_FATHER_NAME",
            upperCase: true
          },
          {
            label: "Date of Death",
            code: "SEC_B_DOD",
            upperCase: false
          },
          {
            label: "Place of Death",
            code: "SEC_B_PLACE_OF_DEATH",
            upperCase: true
          },
          {
            label: "Nature of Death",
            code: "SEC_B_NATURE_OF_DEATH",
            upperCase: true
          },
          {
            label: "Cause of Death",
            code: "SEC_B_CAUSE_OF_DEATH",
            upperCase: true
          },         
          
        ]
      },
      familyDoctor: {
        fields: [
          {
            label: "Name",
            code: "SEC_B_FAMILY_DOCTOR",
            upperCase: true
          },
          {
            label: "Registration No.",
            code: "SEC_B_FAMILY_DOCTOR_REG_NO",
            upperCase: true
          },
          {
            label: "Contact No.",
            code: "SEC_B_FAMILY_DOCTOR_CON_NO",
            upperCase: false
          },
        ]
      },
      lastDoctor: {
        fields: [
          {
            label: "Name",
            code: "SEC_B_LAST_TREATED_DOCTOR_NAME",
            upperCase: true
          },
          {
            label: "Registration No.",
            code: "SEC_B_LAST_TREATED_DOCTOR_REG_NO",
            upperCase: true
          },
          {
            label: "Contact No.",
            code: "SEC_B_LAST_TREATED_DOCTOR_CON_NO",
            upperCase: false
          },
        ]
      },
      employer: {
        fields: [
          {
            label: "Last Employer details",
            code: "SEC_B_LAST_EMPLOYER_DTL",
            upperCase: true
          },
          {
            label: "Name of the Company",
            code: "SEC_B_NAME_OF_THE_COMPANY",
            upperCase: true
          },
          {
            label: "Name of contact person",
            code: "SEC_B_NAME_OF_THE_CONTACT",
            upperCase: true
          },
          {
            label: "Contact No.",
            code: "SEC_B_NAME_OF_THE_CONTACT_NO",
            upperCase: false
          },          
        ]
      }
    },
    illness: {
      fields: [
        {
          label: "Date of diagnosis of illness",
          code: "SEC_B_DATE_OF_DIAGNOSIS_ILLNESS",
          upperCase: true
        },
        /* {
          label: "Nature of illness",
          code: "SEC_B_NATURE_OF_ILLNESS_AND_HABIT_OF_INSURED"
        }, */
        {
          label: "Hypertension",
          code: "SEC_B_NATURE_OF_ILLNESS_AND_HABIT_OF_INSURED_HYPERTENSION",
          upperCase: true
        },
        {
          label: "Diabetes",
          code: "SEC_B_NATURE_OF_ILLNESS_AND_HABIT_OF_INSURED_DIABETES",
          upperCase: true
        },
        {
          label: "Heart disease",
          code: "SEC_B_NATURE_OF_ILLNESS_AND_HABIT_OF_INSURED_HEART_DISEASE",
          upperCase: true
        },
        {
          label: "Liver disease",
          code: "SEC_B_NATURE_OF_ILLNESS_AND_HABIT_OF_INSURED_LIVER_DISEASE",
          upperCase: true
        },
        {
          label: "Kidney disease",
          code: "SEC_B_NATURE_OF_ILLNESS_AND_HABIT_OF_INSURED_KIDNEY_DISEASE",
          upperCase: true
        },
        {
          label: "Cancer",
          code: "SEC_B_NATURE_OF_ILLNESS_AND_HABIT_OF_INSURED_CANCER",
          upperCase: true
        },
        {
          label: "Smoking",
          code: "SEC_B_NATURE_OF_ILLNESS_AND_HABIT_OF_INSURED_SMOKING",
          upperCase: true
        },
        {
          label: "Tobacco",
          code: "SEC_B_NATURE_OF_ILLNESS_AND_HABIT_OF_INSURED_TOBACCO",
          upperCase: true
        },        
        {
          label: "Drugs",
          code: "SEC_B_NATURE_OF_ILLNESS_AND_HABIT_OF_INSURED_DRUGS",
          upperCase: true
        },        
        {
          label: "Duration of Consumption (in months)",
          code: "SEC_B_DURATION_OF_CONSUMPTION",
          upperCase: true
        },
        {
          label: "Quantity Consumed (in mg)",
          code: "SEC_B_QUANTITY_CONSUMED",
          upperCase: true
        },
        {
          label: "Other",
          code: "SEC_B_NATURE_OF_ILLNESS_OTHER",
          upperCase: true
        },
      ]
    },
    claimantDtls: {
      fields: [
        {
          label: "Claimant Name",
          code: "SEC_B_DTLS_OF_CLAIMANT_NAME",
          upperCase: true
        },
        {
          label: "Date of Birth",
          code: "SEC_B_DTLS_OF_CLAIMANT_DOB",
          upperCase: true
        },
        {
          label: "Address",
          code: "SEC_B_DTLS_OF_CLAIMANT_ADDR",
          upperCase: true
        },
        {
          label: "Pincode",
          code: "SEC_B_DTLS_OF_CLAIMANT_PINCODE",
          upperCase: false
        },
        {
          label: "Contact No.",
          code: "SEC_B_DTLS_OF_CLAIMANT_CONTACTNO",
          upperCase: false
        },
        {
          label: "Office &/ or Personal Email ID",
          code: "SEC_B_DTLS_OF_CLAIMANT_EMAIL",
          upperCase: false
        },
        {
          label: "Relation with the Life Assured",
          code: "SEC_B_RELATION_WTH_LA",
          upperCase: true
        },
        {
          label: "Claimant's Title",
          code: "SEC_B_CLAIMANT_TITLE",
          upperCase: true
        },
        {
          label: "Claimant's PAN Details",
          code: "SEC_B_PAN_NUMBER",
          upperCase: false
        },
        {
          label: "Politically exposed person",
          code: "SEC_B_PEP_STATUS",
          upperCase: true
        },
        {
          label: "US Person",
          code: "SEC_B_US_PERSON",
          upperCase: true
        },
      ]
    },
    claimantBank: {
      fields: [
        {
          label: "Bank Account No.",
          code: "SEC_B_BANK_ACCOUNT_NUM",
          upperCase: false
        },
        {
          label: "Account Holder Name",
          code: "SEC_B_ACCOUNT_HOLDER_NAME",
          upperCase: true
        },
        {
          label: "Bank Name & Branch",
          code: "SEC_B_BANK_NAME",
          upperCase: true
        },
        {
          label: "Bank Branch",
          code: "SEC_B_BRFANCH_NAME",
          upperCase: true
        },
        {
          label: "Account Type",
          code: "SEC_B_ACCOUNT_TYPE",
          upperCase: true
        },
        {
          label: "IFSC",
          code: "SEC_B_IFSC",
          upperCase: true
        },
        {
          label: "MICR",
          code: "SEC_B_MICR",
          upperCase: true
        },
        {
          label: "Mode",
          code: "SEC_B_MODE",
          upperCase: true
        },
      ]
    }
  }
  data: any;
  consentCheckbox: FormControl = new FormControl(false);
  reason: FormControl = new FormControl('', Validators.required);
  otherInsurance = null;
  columns: string[] = ['policy', 'company', 'sum', 'status'];
  @ViewChild('reasonPopup') reasonPopup: TemplateRef<any>;
  rejectReasons: Array<any> = [
    {
      code: '1',
      label: "NEFT mismatch"
    },
    {
      code: '2',
      label: "PAN mismatch"
    },
    {
      code: '3',
      label: "KYC mismatch"
    },
    {
      code: '4',
      label: "Information Detail mismatch"
    },
  ];
  annuityCheckbox = [
    { isChecked: false, label: 'Entire amount as Lumpsum' },
    { isChecked: false, label: 'Entire amount as Annuity' },
    { isChecked: false, label: 'Part as Annuity Part as Lumpsum' },
    { isChecked: false, label: 'As Installments' },
  ]

  declarations = [
    "I hereby declare all the details filled/furnished above are true and correct to the best of my knowledge and belief",
    "I hereby warrant the truth and correctness of the foregoing particulars in every respect and I agree that if I have made or shall make any false or untrue statement,suppress or conceal any material fact, my right to claim shall be absolutely forfeited",
    "I understand and agree that the submission of this form does not mean that the request will be processed",
    "I understand that any payout under the policy shall be strictly in accordance with the policy terms and conditions",
    "Any payment shall be subject to realization of the last renewal premium payment",
    "I authorise all the medical establishments (medical labs included), government institutions (police, revenue, etc.) to reveal the treatment information including HIV/AIDS and others, related to the LA, to HDFC Life Insurance Company Ltd. from both the past and present",
    "A photo copy of this declaration shall be considered as valid and effective",
    "I authorise HDFC Life Insurance Company Ltd. to share and obtain information on behalf of me with any reinsurer, insurance association, medical authorities, other insurers, statutory authorities, employer, court, governmental body, regulator using an investigation agency or other service hereby provide my consent for the same"
  ]

  deathClaimDtls = {
    /* "SEC_A_POLICY_NO": "20582432",
    "SML_POLICY": "20582432, 20582432, 20582432, 20582432, 20582432, 20582432, 20582432, 20582432, 20582432, 20582432, 20582432, 20582432, 20582432, 20582432, 20582432, 20582432, 20582432, 20582432",
    "SEC_B_NAME_OF_LA": "RANJIT GOWDA",
    "SEC_B_FATHER_NAME": null,
    "SEC_B_DOD": "20-05-2020",
    "SEC_B_PLACE_OF_DEATH": "Gujarat",
    "SEC_B_FAMILY_DOCTOR": null,
    "SEC_B_FAMILY_DOCTOR_REG_NO": null,
    "SEC_B_FAMILY_DOCTOR_CON_NO": null,
    "SEC_B_LAST_TREATED_DOCTOR_NAME": null,
    "SEC_B_LAST_TREATED_DOCTOR_REG_NO": null,
    "SEC_B_LAST_TREATED_DOCTOR_CON_NO": null,
    "SEC_B_LAST_EMPLOYER_DTL": null,
    "SEC_B_NAME_OF_THE_COMPANY": null,
    "SEC_B_NAME_OF_THE_CONTACT": null,
    "SEC_B_NAME_OF_THE_CONTACT_NO": "NEED TO CHECK",
    "SEC_B_NATURE_OF_DEATH": "Natural",
    "SEC_B_CAUSE_OF_DEATH": null,
    "SEC_B_NATURE_OF_ILLNESS_HABIT": "NEED TO CHECK",
    "SEC_B_DT_OF_DIAGNOSIS_ILLNESS": "NEED TO CHECK",
    "OTHER_INSURANCE_DTL": [
          {POLICY_NO: "NA", COMPANY_NAME: "NA", SUM_ASSURED: "NA", STATUS: "NA"},
    {POLICY_NO: "NA", COMPANY_NAME: "NA", SUM_ASSURED: "NA", STATUS: "NA"},
    {POLICY_NO: "NA", COMPANY_NAME: "NA", SUM_ASSURED: "NA", STATUS: "NA"},

    ],
    "SEC_B_DTLS_OF_CLAIMANT_ADDR": "ADDRESS1,kdkin,wwfgfhjnh,Mumbai,Maharastra,400064,India",
    "SEC_B_DTLS_OF_CLAIMANT_PINCODE": "400064",
    "SEC_B_DTLS_OF_CLAIMANT_CONTACTNO": "9409428859",
    "SEC_B_DTLS_OF_CLAIMANT_EMAIL": "claimstesting@hdfclife.com",
    "SEC_B_RELATION_WTH_LA": "Mother",
    "SEC_B_CLAIMANT_TITLE": "Beneficiary",
        "SEC_B_PAN_NUMBER": null,
        "SEC_B_PEP_STATUS": null,
        "SEC_B_US_PERSON": null,
        "SEC_B_ANNUITY_OPTION": null,
        "SEC_B_BANK_ACCOUNT_NUM": "12345",
        "SEC_B_ACCOUNT_HOLDER_NAME": "Client Migrated",
        "SEC_B_BANK_NAME": "ALLAHABAD BANK",
        "SEC_B_BRFANCH_NAME": "ALLAHABAD BANK CHAPKI",
        "SEC_B_ACCOUNT_TYPE": "Savings",
        "SEC_B_IFSC": "ALLA0210958",
        "SEC_B_MICR": "231010153",
            "SEC_B_DTLS_OF_CLAIMANT_NAME": "SanketDevidas K",
            "SEC_B_DTLS_OF_CLAIMANT_DOB": "06-02-1985",
            "SEC_B_MODE": "NEFT" */
  }

  userDtls: any = {};
  srno: string = '';
  activityNo: string = '';
  token: string;
 
  constructor(public customLinkService: CustomLinkService, public dialog: MatDialog, public dataService: DataService, public httpService: HttpService, public message: MessageService, public utils: Utils, public route: ActivatedRoute) {
    this.dataService.loginResp$.subscribe((isChanged) => {
      if (isChanged) {
        this.userDtls = this.dataService.getLoginResp();
        this.srno  = this.userDtls && this.userDtls.srno ? this.userDtls.srno : '';
        this.activityNo = this.userDtls && this.userDtls.Invest_activity_no ? this.userDtls.Invest_activity_no : '';
        this.getDeathClaimFormDtls(); 
      }       
    });
  }

  ngOnInit(): void {
    this.userDtls = this.dataService.getLoginResp();
    this.srno  = this.userDtls && this.userDtls.srno ? this.userDtls.srno : '';
    this.activityNo = this.userDtls && this.userDtls.Invest_activity_no ? this.userDtls.Invest_activity_no : '';
    if (this.srno) {
      this.getDeathClaimFormDtls();
    }  
  }
  closeAllPopup() {
    this.dialog.closeAll();
  }
  onApprove() {
    this.updateStatus('accept');
  }
  onReject() {
    this.dialog.open(this.reasonPopup, {
      panelClass: 'reason-popup-container', 
      backdropClass: 'reason-popup-backdrop', 
      disableClose: true,
    });
  }
  onReasonSubmit() {
    if (this.reason.valid) {
      this.updateStatus('reject', this.reason.value)
    } else {
      this.message.openSnackBar("Please select a reason", 'xy', ['error-snackbar']);
    }
  }
  getDeathClaimFormDtls() {
    let reqBody = {
      "sr": this.srno
    }
    return this.customLinkService.deathClaimForm(reqBody).subscribe((resp) => {
      if (this.httpService.isSuccessResponse(resp)) {
        this.deathClaimDtls = resp.body;
        for (let key of Object.keys(this.deathClaimDtls)) {
          if (this.deathClaimDtls[key] == 'Y') {
            this.deathClaimDtls[key] = 'Yes';
          } else if (this.deathClaimDtls[key] == 'N' || !this.deathClaimDtls[key]) {
            this.deathClaimDtls[key] = 'No';
          }
        }     
        /* if (this.deathClaimDtls && this.deathClaimDtls['SEC_B_NATURE_OF_ILLNESS_AND_HABIT_OF_INSURED']) {
          let illness = this.deathClaimDtls['SEC_B_NATURE_OF_ILLNESS_AND_HABIT_OF_INSURED'].split(',').map(e => e.trim());
          for (let i of illness) {
            this.deathClaimDtls[i] = 'Y'
          }
        } */
        this.setAnnuityCheckbox(this.deathClaimDtls["SEC_B_ANNUITY_OPTION"]);
        if (this.deathClaimDtls && this.deathClaimDtls['OTHER_INSURANCE_DTL'] && this.deathClaimDtls['OTHER_INSURANCE_DTL'].length > 0) {
          this.otherInsurance = this.deathClaimDtls['OTHER_INSURANCE_DTL'];
        } else {
          this.otherInsurance = [
            {POLICY_NO: "NA", COMPANY_NAME: "NA", SUM_ASSURED: "NA", STATUS: "NA"},
          ]
        }
      } else {
        this.httpService.apiErrorHandler(resp);
      }
    });
  } 
  updateStatus(status, rejectReason = '') {
    let reqBody = {
      "sr": this.srno,
      "status": status,
      "reject_reason": rejectReason,
      "activity_no": this.activityNo
    }
    return this.customLinkService.deathClaimStatusUpdate(reqBody).subscribe(resp => {
      this.dialog.closeAll();
      if (this.httpService.isSuccessResponse(resp)) {
        if (resp && resp.body && resp.body.Responsetsubmit == "Y") {
          //this.message.openSnackBar('Thank you for your response', 'xy', ['success-snackbar']);
          this.message.showSuccessMsg('Thank you for your response', {
            showClose: true,
            success: true,
          });
        } else if (resp && resp.body && resp.body.ERR_MSG) {
          this.message.openSnackBar(resp.body.ERR_MSG, 'xy', ['error-snackbar']);
        } else {
          this.message.openSnackBar("Something went wrong. Please try after sometime.", 'xy', ['error-snackbar']);
        }
      } else {
        this.httpService.apiErrorHandler(resp);
      }
    })
  }
  setAnnuityCheckbox(annuityOpt) {
    for (let idx = 0; idx < this.annuityCheckbox.length; idx++) {
      this.annuityCheckbox[idx].isChecked = false;
    }
    if (annuityOpt === 'Entire amount as Lumpsum') this.annuityCheckbox[0].isChecked = true;
    if (annuityOpt === 'Entire amount as Annuity') this.annuityCheckbox[1].isChecked = true;
    if (annuityOpt === 'Part Annuity Part Lumpsum') this.annuityCheckbox[2].isChecked = true;
    if (annuityOpt === 'As installments') this.annuityCheckbox[3].isChecked = true;
  }



}
