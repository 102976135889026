import { Component, OnInit, TemplateRef } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Utils } from 'src/app/utils/common-functions';
import { HttpService } from 'src/app/services/http/http.service';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from 'src/app/services/data/data.service';
import { CustomLinkService } from 'src/app/services/custom-link/custom-link.service';
import { MessagePopupComponent } from '../../shared/components/message-popup/message-popup.component';
import * as moment from 'moment';

@Component({
  selector: 'app-wellness-voucher-customlink',
  templateUrl: './wellness-voucher-customlink.component.html',
  styleUrls: ['./wellness-voucher-customlink.component.css']
})
export class WellnessVoucherCustomlinkComponent implements OnInit {
  clientId: any;

  wellnessVoucherDetails: any = [];
  voucherDetails: any;
  voucherPdfStream: any;
  dtOfIssuance: string;
  displayMessage: boolean = false;
  voucherDisclaimer: Array<string> = [
    "This voucher is offered under the Wellness Benefit available under your Gold plan.",
    "It is non-transferable, and the benefits cannot be exchanged for cash.",
    "The benefits can be availed only at network diagnostic centres.",
    "You are requested to dial our Toll free helpline 1800-102-4252 to get the latest information on network diagnostic centres and avail the services under this benefit.",
    "This voucher is not offered under any rebate and HDFC Life does not in any way seek to influence your decision by usage of this voucher.",
    "No charges or fees shall be leviable on availing this benefit and it does not affect your annual limit"
  ];
  voucherId: any;
  isCustomLink: boolean;
  httpHeaders = {
    "apiname": "CUST_LOGIN"
  }
  voucherIssueDt: any;

  constructor(public utils: Utils,
    public customLinkService: CustomLinkService,
    public _httpService: HttpService,
    public dialog: MatDialog,
    public router: Router,
    public route: ActivatedRoute,
    public dataService: DataService, ) { }

  ngOnInit(): void {
    let loginSrc = this.utils.getLocalData('action',false);
    if(loginSrc == null || loginSrc == undefined) {
      this.router.navigate(['/my-link'], {queryParams: {action: 'cp_voucherPageUSL',id:this.route.snapshot.queryParamMap.get('id')}, queryParamsHandling: "merge"});
    }
    else {
    this.dataService.setIsCustomLink(true);
    this.isCustomLink = this.dataService.getIsCustomLink();
    this.route.queryParamMap.subscribe((params: any) => {
      let paramValue = { ...params }
      if (paramValue != undefined && paramValue != null && paramValue != {}) {
        this.voucherId = this.route.snapshot.queryParamMap.get('id');
      }
      if (this.voucherId) {
        // return this.init().subscribe((response) => {
          // this._httpService.initResp = response;
          this.getVoucherDetails();
        // });
      }
      else {
        this.dialog.open(MessagePopupComponent, {
          width: '500px',
          panelClass: 'message-popup-panel',
          backdropClass: 'email-voc-popup', disableClose: true,
          data: {
            closeIcon: false,
            title: "Error Message",
            mainMessageHtml: "You are not authorised to view this page. Kindly get in touch with your nearest Branch or write a email to our Service desk.",
            cancelBtnLabel: "OK",
            source: 'voc'
          }
        });
      }
    })
  }
  }
  getVoucherDetails() {
    let reqbody = {
      voucherid: this.voucherId,
    }
    return this.customLinkService.getVoucherDetails(reqbody).subscribe((response) => {
      if (this._httpService.isSuccessResponse(response)) {
        if (response.body && response.body.length != 0) {
          this.voucherDetails = response.body;
          for (let item of this.voucherDetails) {
            // this.voucherID = item.VOUCHER_ID
            this.clientId = item.CLIENT_ID
            this.voucherIssueDt = moment(item.VOUCHER_ISSUE_DT,"DD-MMMM-YYYY").format('DD-MM-YYYY');
          }
        }
        else {
          this.displayMessage = true
        }
      } else {
        this._httpService.apiErrorHandler(response);
      }
    }),
      catchError((error) => throwError(error));
  }
  generateVoucherPdf(type: String) {
    let reqbody = {
      clientid: this.clientId,
      voucherid: this.voucherId
    }
    return this.customLinkService.generateVoucherPdf(reqbody).subscribe((response) => {
      if (this._httpService.isSuccessResponse(response)) {
        this.voucherPdfStream = response.body.pdfStream;
        if (this.voucherPdfStream) {
          if (type == "view") {
            this.utils.base64ToPDF(this.voucherPdfStream, "Wellness Voucher", "view");
          }
          else {
            this.utils.base64ToPDF(this.voucherPdfStream, "Wellness Voucher", "download");
          }
        }
      } else {
        this._httpService.apiErrorHandler(response);
      }
    }),
      catchError((error) => throwError(error));
  }
  openDisclaimerPopup(templateRef: TemplateRef<any>) {
    this.dialog.open(templateRef, { width: '500px', panelClass: 'disclaimer-popup-container', backdropClass: 'disclaimer-popup-backdrop', disableClose: true });
  }
  closeDialog() {
    this.dialog.closeAll();
  }
  // init() {
    // return this._httpService.init(this.httpHeaders);
  // }
}
