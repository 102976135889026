import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CustomLinkComponent } from './custom-link/custom-link.component';
import { DeclarationComponent } from './declaration/declaration.component';
import { HospitalListComponent } from './hospital-list/hospital-list.component';
import { EmailVocComponent } from './email-voc/email-voc.component';
import { EditNeftDeepLinkComponent } from './edit-neft-deep-link/edit-neft-deep-link.component';
import { AuthGuard } from 'src/app/guards/auth/auth.guard';
import { VservPageComponent } from './vserv-page/vserv-page.component';
import { SrTrackComponent } from './sr-track/sr-track.component';
import { WellnessVoucherCustomlinkComponent } from './wellness-voucher-customlink/wellness-voucher-customlink.component';
import { SurrenderComponent } from './surrender/surrender.component';
import { CustomLinkDocUploadComponent } from './custom-link-doc-upload/custom-link-doc-upload.component';
import { AgentDocUploadComponent } from './agent-doc-upload/agent-doc-upload.component';
import { AgentSrScreenComponent } from './agent-sr-screen/agent-sr-screen.component';
import { ChequePickupComponent } from './cheque-pickup/cheque-pickup.component';
import { DeathClaimComponent } from './death-claim/death-claim.component';
import { BlankComponent } from './blank/blank.component';


const routes: Routes = [
  { path: '', component: CustomLinkComponent },
  { path: 'declaration', component: DeclarationComponent },
  { path: 'hospitals', component: HospitalListComponent },
  { path: 'voc' , component: EmailVocComponent},
  { path: 'update-bank-details' , component: EditNeftDeepLinkComponent, canActivate: [AuthGuard] },
  { path: 'surrender' , component: SurrenderComponent, canActivate: [AuthGuard] },
  { path: 'vserv' , component: VservPageComponent},
  { path: 'sr-track' , component: SrTrackComponent},
  { path: 'wb-voucher' , component: WellnessVoucherCustomlinkComponent},
  { path: 'upload' , component: CustomLinkDocUploadComponent, canActivate: [AuthGuard] },
  { path: 'digital-consent' , component: CustomLinkDocUploadComponent, canActivate: [AuthGuard] },
  { path: 'agent-doc-upload' , component: AgentDocUploadComponent},
  { path: 'photo-upload' , component: AgentSrScreenComponent},
  { path: 'sr-list' , component: AgentSrScreenComponent},
  { path: 'cheque-pickup', component: ChequePickupComponent, canActivate: [AuthGuard] },
  { path: 'death-claim', component: DeathClaimComponent/* , canActivate: [AuthGuard] */ },
  { path: 'death-claim-pdf', component: BlankComponent },
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CustomLinkRoutingModule { }