import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomLinkService } from 'src/app/services/custom-link/custom-link.service';
import { HttpService } from 'src/app/services/http/http.service';
import { Utils } from 'src/app/utils/common-functions';
import { MessagePopupComponent } from '../../shared/components/message-popup/message-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { MessageService } from 'src/app/services/message/message.service';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Component({
  selector: 'app-blank',
  templateUrl: './blank.component.html',
  styleUrls: ['./blank.component.css']
})
export class BlankComponent implements OnInit {
  token: string = '';

  constructor(public route: ActivatedRoute, public customLinkService: CustomLinkService, public httpService: HttpService, public utils: Utils, public dialog: MatDialog, public message: MessageService) { }

  ngOnInit(): void {
    this.token = this.route.snapshot.queryParamMap.get('token');
    if (this.token) {
      this.init();
    }
  }

  getDCFpdf() {
    let reqBody = {
      "Token": this.token
    }
    this.customLinkService.deathClaimPdf(reqBody).subscribe(resp => {
      if (this.httpService.isSuccessResponse(resp)) {
        if (resp.body && resp.body.pdfStream) {
          this.utils.base64ToPDF(resp.body.pdfStream, "Death_Claim_Form", "download");
        }
      } else {
        let msg = resp.head && resp.head.errordetails && resp.head.errordetails.message ? resp.head.errordetails.message : 'Something went wrong. Please try after sometime';
        this.openErrorPopup(msg);
      }
    })
  }
  openErrorPopup(errorMsg) {
    this.dialog.open(MessagePopupComponent, {
      width: '500px',
      panelClass: 'message-popup-panel',
      backdropClass: 'email-voc-popup', disableClose: true,
      data: {
        closeIcon: false,
        title: "Error",
        mainMessageHtml: errorMsg,  
      }
    });
  }
  init() {
    return this.httpService.init({}).subscribe((resp) => {
      this.httpService.initResp = resp;
      this.getDCFpdf()
    }),
      catchError((error) => this.handleError.bind(this, error));
  }
  handleError(error: any): any {
    this.openErrorPopup("Something went wrong. Please try again after sometime");
    throwError(error);
  }

}
