import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { DataService } from 'src/app/services/data/data.service';
import { HttpService } from 'src/app/services/http/http.service';
import { Utils } from 'src/app/utils/common-functions';
import { throwError, from } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { loginService } from 'src/app/services/login/login.service';
import { Router, ActivatedRoute } from '@angular/router';
import { loginReqBody } from "src/app/modules/login/login/login-data-modal";
import { toasterReqBody } from 'src/app/modules/login/login/login-data-modal';
import { MyPoliciesService } from 'src/app/services/my-policies/my-policies.service';
import { PasaOfferPopupComponent } from 'src/app/modules/my-policies/pasa-offer-popup/pasa-offer-popup.component';
import { MyPoliciesDialogComponent } from 'src/app/modules/my-policies/my-policies-dialog/my-policies-dialog.component';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddnomineeService } from 'src/app/modules/my-policies/addnominee-service/addnominee.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { serviceURL } from 'src/environments/environment';
import { MessageService } from 'src/app/services/message/message.service';
import { inactivePolicyStatus } from 'src/app/utils/app-constants';
import { MyAccountService } from 'src/app/services/my-account/my-account.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CreateSRService } from 'src/app/services/create-sr/create-s-r.service';
import { CustomLinkService } from 'src/app/services/custom-link/custom-link.service';

@Component({
  selector: 'app-switch-policy-group-popup',
  templateUrl: './switch-policy-group-popup.component.html',
  styleUrls: ['./switch-policy-group-popup.component.css']
})
export class SwitchPolicyGroupPopupComponent implements OnInit {
  isHNIUSer: boolean = false;
  userDetails: any = {};
  loginParams: any = [];
  expInfoPopupData = [];
  showToaster: boolean = false;
  toasterData: any;
  checkSumResp: any;
  source: String;
  funcType: String;
  @ViewChild('exideForm') exideForm: ElementRef;
  exideURL = serviceURL.exideDashboardURL;
  loginResponse: any;
  chosenClientId: any;
  statusMessage: any;
  cp_login_mode: any;
  cp_loginid: any;
  dateofbirth: any;
  clientid: any;
  validateUserReq: any;
  validateUserResponse: any;
  dedupeReq: any;
  policyCardDtls: any;
  dedupeResponse: any;
  dedupeResp: string;
  custSource: any;
  policyId: any;
  dob: any;
  displayFlag: boolean = false;
  loginId: any;
  isRedirectionAllowed: boolean;
  displayDetails: Array<any> =[];
  medium: any;
  showAddPolicyMsg: boolean = false;
  validateExideForm: boolean = false;
  srInputParams: { srNum: string; srType: string; }[];
  action: string;
  caseId: any;
  exidePolicyArray: Array<any> =[];
  get device() {
    return this.deviceService.getDeviceInfo();
  }
  constructor(public dataService: DataService,    public message: MessageService,
    public addnomineeService: AddnomineeService,public auth: AuthService, @Inject(MAT_DIALOG_DATA) public data, public _httpService: HttpService, public dialog: MatDialog, public myPoliciesService: MyPoliciesService, public utils: Utils, public loginService: loginService, public router: Router,public route: ActivatedRoute,public myAccountService: MyAccountService,private deviceService: DeviceDetectorService,public createSrService: CreateSRService,public customLinkService: CustomLinkService) { }

  ngOnInit(): void {
    this.userDetails = this.dataService.getLoginResp();
    if (this.userDetails && this.userDetails.HNI_FLG === "Y") {
      this.isHNIUSer = true;
    }
    this.dedupeReq = this.addnomineeService.getLoginDetail();
    setTimeout(() => {
      this.chosenClientId = this.dataService.getSelectedSwitchClientIdDtls();
      this.dedupeResponse = this.dataService.getDedupeData();
        this.dataService.setDedupeResp(this.dedupeResponse.dedUpeDetails);
        for(let item of this.dedupeResponse.dedUpeDetails) {
          this.dedupeResp = item;
          if(item.policy_details) {
          if(!((item.policy_details.length == "0" || item.policy_details == [])&& item.customer_source == "Exide")) {
            this.isRedirectionAllowed = false;
          for(let i of item.policy_details) {
          if(inactivePolicyStatus.includes(i.policy_status)) {
            i['status'] = "Inactive";
          }
          else {
            i['status'] = "Active";
          }
        }
        }
        this.displayDetails.push(item);
        this.displayDetails.sort((a, b) => {
          if (a.policy_details.every(policy => policy["status"] == 'Active') && !b.policy_details.every(policy => policy["status"] == 'Active')) {
            return -1;
          } else if (!a.policy_details.every(policy => policy["status"] == 'Active') && b.policy_details.every(policy => policy["status"] == 'Active')) {
            return 1;
          } else {
            return 0
          }
        })
        this.displayDetails.forEach(item => {
          item.policy_details.sort((a , b) => {
            if (a.status == 'Active' && b.status != 'Active') {
              return -1;
            } else if (a.status != 'Active' && b.status == 'Active') {
              return 1;
            } else {
              return 0
            }
          })
        })
      }
    }
    this.showAddPolicyMsg = this.displayDetails.filter(x => x.customer_source === 'HDFC').length > 1
    }, 10);
    this.medium = this.route.snapshot.queryParamMap.get('medium');
  }

  selectClientID(eve) {
    this.exidePolicyArray =[];
    this.chosenClientId = eve.value.customer_id;
    this.custSource = eve.value.customer_source; 
    this.policyId = eve.value.policy_details[0].policy_id;
    this.dataService.setSelectedSwitchClientIdDtls(this.chosenClientId);
    if((this.custSource).toUpperCase() == "EXIDE") {
    //  { // this.validateExideForm = true;
    //   let policyList = eve.value.policy_details;
    //   for(let item of policyList){
    //     this.exidePolicyArray.push(item.policy_id)
    //   }
    //   this.covidExideValidation();}
      this.generateChecksum(); 
    }
    else {
      this.login();
    }
  }
  covidExideValidation(){
    let reqBody = {
      policyNo: this.exidePolicyArray.toString(),
      source: "customer_portal"
    }
    return this.customLinkService.getCovidExideValidateResp(reqBody).subscribe((resp) => {
      if (this._httpService.isSuccessResponse(resp)) {
        if(resp.body.CASEID && resp.body.CASEID != "" && resp.body.CASEID != null){
          this.caseId = resp.body.CASEID;
          this.validateExideForm = true;
        }
        else {
          this.generateChecksum(); 
        }
      }
      else {
        this._httpService.apiErrorHandler(resp);
      }
    }),
    catchError((error) => throwError(error));
  }
  redirectExideLink(){
    this.generateChecksum(); 
  }
  redirectCovidForm(){
    this.router.navigate(['/dcq']);
    this.dialog.closeAll();
  }
  getToaster() {
    let toasterReqBody: toasterReqBody = {
      searchtype: 'PopupToasterRule',
      clientid: this.utils.getClientId(),
    }
    return this.myPoliciesService.getToasterPopup(toasterReqBody)
      .subscribe((resp) => {
        if (this._httpService.isSuccessResponse(resp)) {
          resp.body.sort(this.myPoliciesService.compareToasterObj);
          this.showToaster = this.myPoliciesService.showHideToaster(resp.body);
          this.toasterData = this.myPoliciesService.setToasterMsg(resp.body);
          this.expInfoPopupData = this.myPoliciesService.getExpInfoPopup(resp.body);
          let popupshow = false;
          for(let item of this.expInfoPopupData){
            if(item.flag == "neftexp" ||  item.flag == "panexp" || item.flag == "mobemailexp"){
              popupshow = true; 
            }
        }
        if(!popupshow){
        for(let item of this.expInfoPopupData){
          if( item.flag == "neftFlag"  ){
            this.getBankDtls(); 
            break;
          }
          else{
            if(item.flag == "contactFlag"){
              this.getContactDtls();
            }
          }
      }}
          if (this.expInfoPopupData.length == 0) {
            //this.dialog.open(PasaOfferPopupComponent, { panelClass: 'pasa-offer-dialog', backdropClass: 'pasa-offer-dialog-backdrop' });
          } else {
            if(popupshow){
              this.openExpiredPopup();
            }
          }
        } else {
          this._httpService.apiErrorHandler(resp);
        }
      }),
      catchError((error) => throwError(error));
  }

  openExpiredPopup() {
    this.dialog.open(MyPoliciesDialogComponent, {
      panelClass: 'my-policies-dialog', disableClose: true,
      data: {
        dtls: this.expInfoPopupData
      }
    });
  }
  generateChecksum() {
    let checkSumReqBody = {
      "policyno": this.policyId,
      "dateofbirth": this.dedupeReq.dateofbirth? this.dedupeReq.dateofbirth :this.userDetails.DATEOFBIRTH,
      "mobileno": "",
      "cp_loginid": this.chosenClientId,
    }
    return this.loginService.generateCheckSum(checkSumReqBody)
      .subscribe((resp) => {
        if (this._httpService.isSuccessResponse(resp)) {
          this.checkSumResp = resp.body.checksum;
          this.source = "HDFCLife";
          this.funcType = "ps";
          this.dob = this.dedupeReq.dateofbirth? this.dedupeReq.dateofbirth :this.userDetails.DATEOFBIRTH;
          if(this.checkSumResp) {
            setTimeout(() => {
              this.submitForm();
            }, 100); 
          }
        } else {
          let trackMsgObj ={"flowType": 'Switch client ID',"error_code":"UEE"}
          this._httpService.apiErrorHandler(resp,null,trackMsgObj);
        }
      }),
      catchError((error) => throwError(error));
  }
  submitForm() {
    if((this.custSource).toUpperCase() == "EXIDE") {
      this.exideForm.nativeElement.submit();
    }
  }
 /*  validateUser() {
    let validateUserReq = {
      "cp_login_mode": this.dedupeReq.cp_login_mode?this.dedupeReq.cp_login_mode:this.userDetails.LOGINMODE,
      "optradio": "",
      "cp_loginid":this.dedupeReq.cp_loginid?this.dedupeReq.cp_loginid:this.userDetails.LOGINID,
      "dateofbirth": this.dedupeReq.dateofbirth? this.dedupeReq.dateofbirth :this.userDetails.DATEOFBIRTH,
    }   

     return this.loginService.validateUser(validateUserReq)
    .subscribe((response) => {
      if (this._httpService.isSuccessResponse(response)) {
        this.validateUserResponse = response.body;
      }
        }),
        catchError((error) => throwError(error))
  } */
  login() {
    let loginReqBody = {
      "requesttype": "CUST_LOGIN",
      "cp_login_mode":this.dedupeReq.cp_login_mode?this.dedupeReq.cp_login_mode:this.userDetails.LOGINMODE,
      "cp_loginid": this.dedupeReq.cp_loginid?this.dedupeReq.cp_loginid:this.userDetails.LOGINID,
      "dateofbirth":this.dedupeReq.dateofbirth? this.dedupeReq.dateofbirth :this.userDetails.DATEOFBIRTH,
      "clientid":this.chosenClientId?this.chosenClientId:this.userDetails.CLIENT_ID,
      "password":"",
      "login_source":"Customer Portal",
      "medium":this.medium,
      "landingScreen": (this.data) ? (this.data): "Switch Client Id",
      "device_type":this.device.deviceType,
      "browser":`${this.device.browser} - ${this.device.browser_version}`,
      "os_type":this.device.os,
    }
    /*  if(loginReqBody.cp_login_mode == "CO") {
      this.loginId = this.userDetails.CLIENT_ID;
    }
    else if(loginReqBody.cp_login_mode == "MO") {
      this.loginId = this.userDetails.MOBILE_NUM
    }
    else if(loginReqBody.cp_login_mode  == "EO") {
      this.loginId = this.userDetails.EMAIL_ID
    }
    else {
      this.loginId = this.policyId
    }  */
    let httpHeaders = {
      "apiname": "CUST_LOGIN"
    }
    return this.loginService.getUserLoginDetails(loginReqBody)
      .subscribe((resp) => {
        if (this._httpService.isSuccessResponse(resp)) {
          this.dataService.setLoginResp(resp.body);
          this.utils.setLocalData('loggedIn', 'true', false);
          this.utils.setLocalData("id", resp.body.CLIENT_ID, false);
          //this.utils.setLocalData("newlogin","true",false);
          this.utils.sendToAnalytics({
            'event': 'logged in', 
            'custid': resp.body.CLIENT_ID,
          });
          let policyCardResp = null;
          this.dataService.setPolicyCardResp(policyCardResp);
          this.myPoliciesService.getPolicyDetails().subscribe(dtls =>{ 
            this.policyCardDtls=dtls;
            });
            // this.router.navigate(['/my-policies']);
            if(this.data === 'Home screen') {
              this.router.navigate(['/my-policies']);
            } else if(this.data === 'Download & Statement') {
              this.router.navigate(['/my-downloads/policy-document']);
            } else if(this.data === 'Update Bank Details') {
              this.router.navigate(['/my-account'],{queryParams: {tab: "bank"}});
            } else if(this.data === 'Update Communication Details') {
              this.router.navigate(['/my-account'],{queryParams: {tab: "contact"}});
            } else if(this.data === 'Update PAN / KYC') {
              this.router.navigate(['/my-account'],{queryParams: {tab: "personal"}});
            } else if(this.data === 'Track Service Requests') {
              this.router.navigate(['/customer-service/service-requests']);
            } else {
              this.router.navigate(['/my-policies']);
            }

            // if (this.dedupeReq.cp_login_mode === 'CO') {
            //   if(this.data?.landingScreen === 'Home screen') {
            //     this.router.navigate(['/my-policies']);
            //   } else if(this.data?.landingScreen === 'Download & Statement') {
            //     this.router.navigate(['/my-downloads/policy-document']);
            //   } else if(this.data?.landingScreen === 'Update Bank Details') {
            //     this.router.navigate(['/my-account'],{queryParams: {tab: "bank"}});
            //   } else if(this.data?.landingScreen === 'Update Communication Details') {
            //     this.router.navigate(['/my-account'],{queryParams: {tab: "contact"}});
            //   } else if(this.data?.landingScreen === 'Update PAN / KYC') {
            //     this.router.navigate(['/my-account'],{queryParams: {tab: "personal"}});
            //   } else {
            //     this.router.navigate(['/my-policies']);
            //   }
            // } else if (this.dedupeReq.cp_login_mode === 'EO') {
            //   if(this.data?.landingScreen === 'Home screen') {
            //     this.router.navigate(['/my-policies']);
            //   } else if(this.data?.landingScreen === 'Download & Statement') {
            //     this.router.navigate(['/my-downloads/policy-document']);
            //   } else if(this.data?.landingScreen === 'Update Bank Details') {
            //     this.router.navigate(['/my-account'],{queryParams: {tab: "bank"}});
            //   } else if(this.data?.landingScreen === 'Update Communication Details') {
            //     this.router.navigate(['/my-account'],{queryParams: {tab: "contact"}});
            //   } else if(this.data?.landingScreen === 'Update PAN / KYC') {
            //     this.router.navigate(['/my-account'],{queryParams: {tab: "personal"}});
            //   } else {
            //     this.router.navigate(['/my-policies']);
            //   }
            // } else if(this.dedupeReq.cp_login_mode === 'MO') {
            //   if(this.data?.landingScreen === 'Home screen') {
            //     this.router.navigate(['/my-policies']);
            //   } else if(this.data?.landingScreen === 'Download & Statement') {
            //     this.router.navigate(['/my-downloads/policy-document']);
            //   } else if(this.data?.landingScreen === 'Update Bank Details') {
            //     this.router.navigate(['/my-account'],{queryParams: {tab: "bank"}});
            //   } else if(this.data?.landingScreen === 'Update Communication Details') {
            //     this.router.navigate(['/my-account'],{queryParams: {tab: "contact"}});
            //   } else if(this.data?.landingScreen === 'Update PAN / KYC') {
            //     this.router.navigate(['/my-account'],{queryParams: {tab: "personal"}});
            //   } else {
            //     this.router.navigate(['/my-policies']);
            //   }
            // } else {
            //   if(this.data?.landingScreen === 'Home screen') {
            //     this.router.navigate(['/my-policies']);
            //   } else if(this.data?.landingScreen === 'Download & Statement') {
            //     this.router.navigate(['/my-downloads/policy-document']);
            //   } else if(this.data?.landingScreen === 'Update Bank Details') {
            //     this.router.navigate(['/my-account'],{queryParams: {tab: "bank"}});
            //   } else if(this.data?.landingScreen === 'Update Communication Details') {
            //     this.router.navigate(['/my-account'],{queryParams: {tab: "contact"}});
            //   } else if(this.data?.landingScreen === 'Update PAN / KYC') {
            //     this.router.navigate(['/my-account'],{queryParams: {tab: "personal"}});
            //   } else {
            //     this.router.navigate(['/my-policies']);
            //   }
            // }
          this.dialog.closeAll();
          this.getToaster();
        }
        else {
          this.statusMessage = resp.head.errordetails.message;
          let trackMsgObj ={"flowType": 'Switch client ID',"error_code":"UEE"};
          this.message.openSnackBar(this.statusMessage,'xy', ['error-snackbar'],trackMsgObj);    
        }
      }),
      catchError((error) => throwError(error))
  }
  getDedupeData() {
    let dedupeReq = {
      "cp_login_mode":this.dedupeReq.cp_login_mode?this.dedupeReq.cp_login_mode:this.userDetails.LOGINMODE,
      "cp_loginid":this.dedupeReq.cp_loginid?this.dedupeReq.cp_loginid:this.userDetails.LOGINID,
      "dateofbirth":this.dedupeReq.dateofbirth? this.dedupeReq.dateofbirth :this.userDetails.DATEOFBIRTH,
      "fields": "policy,profile"
    }
    /* if(dedupeReq.cp_login_mode == "CO") {
      this.loginId = this.userDetails.CLIENT_ID;
    }
    else if(dedupeReq.cp_login_mode == "MO") {
      this.loginId = this.userDetails.MOBILE_NUM
    }
    else if(dedupeReq.cp_login_mode  == "EO") {
      this.loginId = this.userDetails.EMAIL_ID
    }
    else {
      this.loginId = this.policyId
    }   */
    return this.loginService.getDedupeResp(dedupeReq)
    .subscribe((response) => {
      if (this._httpService.isSuccessResponse(response)) {
        this.dedupeResponse = response.body.dedUpeDetails;
        this.dataService.setDedupeResp(this.dedupeResponse);
        for(let item of this.dedupeResponse) {
          this.dedupeResp = item;
          if(!((item.policy_details.length == "0" || item.policy_details == [])&& item.customer_source == "Exide")) {
            this.isRedirectionAllowed = false;
            this.displayDetails.push(item);
          }
        }
    }}),
    catchError((error) => throwError(error))
  }
  getBankDtls() {
    let reqBody = {
      clientid: this.utils.getClientId(),
      mode: 'active'
    }
    return this.myAccountService.getNEFTDetails(reqBody).subscribe((response) => { 
        if (this._httpService.isSuccessResponse(response)) {
          if (response.body && response.body.length != 0) {
            let bankDtls = response.body[0];
            if(bankDtls.ACC_NUM && bankDtls.ACCNAME){
            for (let key of Object.keys(bankDtls)) {
              bankDtls[key] = bankDtls[key] ? bankDtls[key] : "NA";
            }
            this.openConfirmPopup("BANK", bankDtls);
          }
        }
        } else { 
          this.utils.trackUserActions({ 'eventName': `message - ${response.body.errormsg}`,"flowType" : "Switch client ID","error_code":"UEE"});       
          //this._httpService.apiErrorHandler(response);
        }

      }),
      catchError((error) => throwError(error));
  }
  getContactDtls() {
    let getDetails = {
      clientid: this.utils.getClientId(),
    }
    return this.myAccountService.getCContactDetails(getDetails).subscribe((response) => {
      if (this._httpService.isSuccessResponse(response)) {
        if (response.body && response.body.length != 0) {
          let contactDetail = response.body[0];
          if(contactDetail.CONTACT_CELL_PH_NUMBR && contactDetail.CONTACT_EMAIL_ADDRESS){
          for (let key of Object.keys(contactDetail)) {
            contactDetail[key] = contactDetail[key] ? contactDetail[key] : "NA";
          }
          this.openConfirmPopup("CONTACT", contactDetail);
        }
        } 
      } else {
        this.utils.trackUserActions({ 'eventName': `message - ${response.body.errormsg}`,"flowType" : "Switch client ID","error_code":"UEE"});
        //this._httpService.apiErrorHandler(response);
      }
    }),
      catchError((error) => throwError(error));
  } 
  openConfirmPopup(flag: string, dtls: any) {
    this.dialog.open(MyPoliciesDialogComponent, {
      panelClass: 'my-policies-dialog', disableClose: true,
      data: {
        dtls: this.expInfoPopupData,
        confirmPopup: flag,
        displayDtls: dtls
      }
    });
  }
}