import { MessageService } from './../message/message.service';
import { RequestSchema, HttpOptions } from './../../utils/app-data.model';
import { EncryptDecryptService } from './../encrypt-decrypt/encrypt-decrypt.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { ResponseSchema } from 'src/app/utils/app-data.model';
import { Utils } from 'src/app/utils/common-functions';
import { serviceURL } from "src/environments/environment";
import { AuthService } from '../auth/auth.service';
import { SrPopupComponent } from '../../modules/shared/components/sr-popup/sr-popup.component'
import { MatDialog } from '@angular/material/dialog';
import { DataService } from '../data/data.service';

@Injectable({
    providedIn: 'root',
})

/**
 *
 * HttpService
 *
 * Provides a set of functions for CRUD operations
 * utilises Angular HttpClientModule
 *
 */
export class HttpService {
    urlLink: string;
    constructor(
        private http: HttpClient,
        private crypto: EncryptDecryptService,
        private message: MessageService,
        private utils: Utils,
        private auth: AuthService,
        private dialog: MatDialog,
        public dataService: DataService,
    ) { }

    readonly initURL: string = serviceURL.initURL;
    readonly reinitURL : string = serviceURL.reinitURL;
    private _key: string;
    private _iv: string;
    initResp = null;

    // getHttpOptions(httpHeaders): HttpOptions {
    //     let headers = new HttpHeaders({...httpHeaders})
    //     return {
    //         observe: 'response' as const,
    //         // withCredentials: true,
    //         headers: headers
    //     };
    // }

    getHttpOptions(): HttpOptions {
        if (this.utils.getLocalData("source", false) == "CL") {
            return { 
                observe: 'response' as const,
                headers : new HttpHeaders({'source': 'CUSTOMLINK'}) 
            }
        } else if (this.utils.getLocalData("source", false) == "IS") {
            return { 
                observe: 'response' as const,
                headers : new HttpHeaders({'source': 'INSTASERV'}) 
            }
        } else if (this.utils.getLocalData("app", false) == "CA") {
            return { 
                observe: 'response' as const,
                headers : new HttpHeaders({'source': 'CS_APP'}) 
            }
        } else {
            return {
                observe: 'response' as const,
                // withCredentials: true,
            };
        }        
    }

    /**
     * Constructs a `GET` request
     *
     * @param url     The endpoint URL.
     * @param options The HTTP options to send with the request.
     *
     * @return An `Observable` of the HttpResponse body
     */
    get(url: string, options = this.getHttpOptions()): Observable<any> {
        return this.http
            .get<any>(url, options)
            .pipe(
                map((resp) => {
                    let apiResponse: ResponseSchema;
                    if (resp && resp.body && resp.body.enc && this._key && this._iv) {
                        // if (resp?.body?.enc && this._key && this._iv) {
                        apiResponse = this.crypto.decryptJson(
                            resp.body.enc,
                            this._key,
                            this._iv
                        );
                    } else {
                        apiResponse = resp.body;
                    }
                    // return this.isSuccessResponse(apiResponse)
                    //     ? apiResponse.body
                    //     : this.apiErrorHandler(apiResponse);
                    return apiResponse;
                }),
                catchError(this.handleError.bind(this))
            );
    }

    /**
     * Constructs a `POST` request
     *
     * @param url The endpoint URL.
     * @param body The content to replace with.
     * @param options HTTP options (optional)
     *
     * @return An `Observable` of the `HTTPResponse` for the request, with a response body in the
     *     requested type.
     */
    post(url: string, requestBody: RequestSchema, options = this.getHttpOptions()) {
        let postRequest: { enc: string } | RequestSchema;
        if (this._key && this._iv) {
            const encryptedText = this.crypto.encryptJson(
                JSON.stringify(requestBody),
                this._key,
                this._iv
            );
            postRequest = {
                enc: encryptedText,
            };
        } else {
            postRequest = requestBody;
        }
        return this.http
        .post<any>(url, postRequest, options)
        .pipe(
            map((resp) => {
                let apiResponse: ResponseSchema;
                if (resp && resp.body && resp.body.enc && this._key && this._iv) {
                    // if (resp?.body?.enc && this._key && this._iv) {
                    apiResponse = this.crypto.decryptJson(
                        resp.body.enc,
                        this._key,
                        this._iv
                    );
                } else {
                    apiResponse = resp.body;
                }
                return apiResponse;
/*                     if (this.isSuccessResponse(apiResponse)) {
                    return apiResponse.body;
                } else {
                    return this.apiErrorHandler(apiResponse);
                }
*/                }),
            catchError(this.handleError.bind(this))
        );
}

/**
 * ##Init Function - retrieves logged-in user details
 * The initilisation key and vector values for the current session
 * are shared in the headers of init response.
 *
 * @returns
 */
init(httpHeaders): Observable<any> {
    //const headers = this.getHttpOptions(httpHeaders)
    let initResponse: ResponseSchema;
    return this.http
        .post<any>(this.initURL, {}, { observe: 'response' })
        .pipe(
            map((resp) => {
                const aesparams = resp.headers.get('init');
                this.crypto.key = aesparams.split('-')[0];
                this.crypto.iv = aesparams.split('-')[1];
                if (aesparams && resp.body == '0') {
                    this._key = aesparams.split('-')[0];
                    this._iv = aesparams.split('-')[1];                        
                }
                this.dataService.setEncryptionKey(this.crypto.key);
                this.dataService.setEncryptionIv(this.crypto.iv);
                initResponse = resp.body;
                localStorage.clear();                              
                return initResponse;
                /* if (this.isSuccessResponse(initResponse)) {
                    return initResponse.body;
                } else {
                    return this.apiErrorHandler(initResponse);
                } */
            }),
            catchError(this.handleError.bind(this))
        );
    }

    reinit(options: HttpOptions = this.getHttpOptions()): Observable<any> {
        //const headers = this.getHttpOptions(httpHeaders)
        let reinitResponse: ResponseSchema;
        let reqBody = {enc: ""};        
        return this.http
            .post<any>(this.reinitURL, reqBody, options)
            .pipe(
                map((resp) => {
                    const aesparams = resp.headers.get('init');
                    this.crypto.key = aesparams.split('-')[0];
                    this.crypto.iv = aesparams.split('-')[1];
                    this.dataService.setEncryptionKey(this.crypto.key);
                    this.dataService.setEncryptionIv(this.crypto.iv);    
                    if (aesparams && resp && resp.body && resp.body.enc) {
                        this._key = aesparams.split('-')[0];
                        this._iv = aesparams.split('-')[1];                          
                        reinitResponse = this.crypto.decryptJson(
                            resp.body.enc,
                            this._key,
                            this._iv
                        );                              
                    }
                    else {
                    reinitResponse = resp.body;                    
                    }
                    if (this.isSuccessResponse(reinitResponse) && reinitResponse.body && reinitResponse.body.CLIENT_ID) {
                        this.utils.setLocalData("id", reinitResponse.body.CLIENT_ID, false);
                    }                    
                    return reinitResponse;
                    /* if (this.isSuccessResponse(initResponse)) {
                        return initResponse.body;
                    } else {
                        return this.apiErrorHandler(initResponse);
                    } */
                }),
                catchError(this.handleError.bind(this))
            );
    }

    /**
     * ##Error Handler
     * Handles client side errors or server side errors due to network issues
     *
     * @param error
     * @returns
     */
    private handleError(error: HttpErrorResponse) {
        let show = true;
        let errorMessage: string = 'Something went wrong. Please try again after sometime.';
        this.utils.log('Inside handleError ===>', error);
        // To check whether the error is a client side error
        if (error.error instanceof ErrorEvent) {
            console.error(
                `A client side or network error occured: ${error.status} ${error.error.message}`
            );
        } else {
            let decryptedError = null;
            if (error.error && error.error.enc) {
                //if (error?.error && error?.error?.enc) {
                if (!(this._iv && this._key)) {
                    const aesparams = error.headers.get('init');
                    this._key = aesparams.split('-')[0];
                    this._iv = aesparams.split('-')[1];
                }
                decryptedError = this.crypto.decryptJson(
                    error.error.enc,
                    this._key,
                    this._iv
                );
            } else {
                decryptedError = error.error;
                if (decryptedError && decryptedError.head && decryptedError.head.errordetails.status == 401) {
                    this.auth.logout('A');
                    show = false;
                    errorMessage = "Session timed out. Please login again.";
                }
            }
            console.error(
                `A server side error occured. Responded with error code ${error.status}`
            );
            console.error(`Error Details: ${JSON.stringify(decryptedError)}`);
            this.urlLink = error.url
        }

        //this.loader.hide();
        // display error message
        if(!(this.urlLink.includes('getDeduperesponse') || this.urlLink.includes('logout')) && show ) {
        this.message.openSnackBar(errorMessage, 'xy', ['error-snackbar']);
    }

        return throwError(error);
    }

    /**
     * ##API Error Handler
     * @function apiErrorHandler forms an HttpErrorResponse and throws the error as HttpErrorResponse
     *
     * @param apiResponse the api response
     * @returns throws HttpErrorResponse
     */
     public apiErrorHandler(apiResponse: ResponseSchema, showPopup?: boolean, object?:any) {
        let errorMessage: string = "";
        /* if (isVirtualBranch && isVirtualBranch == true) {
            if (apiResponse && apiResponse.head && apiResponse.head.errordetails && apiResponse.head.errordetails.message) {
                errorMessage = apiResponse.head.errordetails.message;
            } else {
                errorMessage = 'Something went wrong. Please try again after sometime.';
            }
            this.dialog.open(SrPopupComponent,
                {
                    disableClose: true,
                    panelClass: 'app-sr-popup',
                    backdropClass: 'sr-popup-backdrop',
                    data: {
                        inputText: errorMessage
                    }
                })
        } else { */
            if (apiResponse && apiResponse.head && apiResponse.head.errordetails && apiResponse.head.errordetails.message) {
                errorMessage = apiResponse.head.errordetails.message;
                if(object){
                    this.utils.trackUserActions({ 'eventName': `message - ${errorMessage}`,...object});   
                }
                else {
                    this.utils.trackUserActions({ 'eventName': `message - ${errorMessage}`,'error_code':'UEE'});   
                }
            } else {
                errorMessage = 'Something went wrong. Please try again after sometime.';
            }
            if (apiResponse && apiResponse.head && apiResponse.head.errordetails && apiResponse.head.errordetails.status == '401') {
                this.auth.logout('A');
                showPopup = false;
                errorMessage = "Session timed out. Please login again.";
            }
            if (showPopup && showPopup == true) {
                this.message.showSuccessMsg(errorMessage, {
                    showClose: true,
                    success: false,
                });
            } else {
                this.message.openSnackBar(errorMessage, 'xy', ['error-snackbar']);
            }
        //}
    }

    /**
     * #isSuccessResponse
     * @function isSuccessResponse checks whether the api response is success or failure
     * @param apiResponse
     * @returns true if api response is success
     */

    public isSuccessResponse(apiResponse: ResponseSchema): boolean {
        if (apiResponse && apiResponse.head && apiResponse.head.statusCode === '200') {
            return true;
        } else {
            return false;
        }
    }

    public showSuccessMsg(message: string, showClose: boolean = false) {
        /* this.dialog.open(SrPopupComponent,
            {
                disableClose: true,
                panelClass: 'app-sr-popup',
                backdropClass: 'sr-popup-backdrop',
                data: {
                    inputText: message,
                    successMsg: true,
                    showClose: showClose,
                }
            }); */
            this.message.showSuccessMsg(message, {
                showClose: showClose,
                success: true,
            });
    }
    
}
