import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { deepLinkRouteMap } from 'src/app/utils/deeplink-route-map';

@Component({
  selector: 'app-custom-link-proxy',
  templateUrl: './custom-link-proxy.component.html',
  styleUrls: ['./custom-link-proxy.component.css']
})
export class CustomLinkProxyComponent implements OnInit {
  routePath: Array<string> = [];
  routePathAndParams: Array<string> = [];
  token: string;
  root: string;

  constructor(public router: Router, public route: ActivatedRoute) { }

  ngOnInit(): void {
    this.token = this.route.snapshot.params['_token'];
    this.routePathAndParams = this.router.url.split('?');
    this.routePath = this.routePathAndParams[0].split('/');
    this.root = this.routePath[1];
    // if (this.root == "sl" || this.root == "LookInPage") {
    //   this.token = this.route.snapshot.queryParamMap.get('t');
    // }
    if (this.root == 'my-dc' || this.root == 'my-dcf' ) {
      this.token = this.route.snapshot.queryParamMap.get('key');
    }
    this.shortLinkHandler();
  }

  shortLinkHandler() {
    this.router.navigate(['/my-link'], { 
      queryParams: this.createQueryParams(this.root)
    });    
  }
  createQueryParams(root) {   
    if (root == "gu") {
      let paramsList: Array<string> = this.routePathAndParams[1] ? this.routePathAndParams[1].split('&') : [];
      const map = this.convertToMap(paramsList);      
      return {
        action: this.getAction(root),
        csps_url_rk: decodeURIComponent(map['code']),
        token: map['token']
      }
    } else if (root == "sr") {
      return {
        action: this.getAction(root),
        sr_num: this.token
      }
    } else if (this.token) {
      return {
        action: this.getAction(root),
        token: this.token,
      }
    }
    else {
      return {
        action: this.getAction(root),
      }
    }
  }
  convertToMap(list) {
    let map = {};
    for (let item of list) {
      let [key, val] = item.split('=');
      map[key] = val;
    }
    return map;
  }
  getAction(root) {
    return Object.keys(deepLinkRouteMap).find(key => deepLinkRouteMap[key]["shortLinkCode"] == root);
  }

}
