import { Injectable } from '@angular/core';
import { DataService } from '../data/data.service';
import { Router } from '@angular/router';
import { Utils } from 'src/app/utils/common-functions';
import { MatDialog } from '@angular/material/dialog';
import { sendToAndroid, sendToIOS, sendToReactNative } from 'src/app/utils/mobile-app-interface';
import { SessionTimerService } from '../session-timer/session-timer.service';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    constructor(public _utils: Utils, public dataService: DataService, public router:Router, public dialog: MatDialog, public sessionTimer : SessionTimerService) {}

    logout(mode = 'A', skipCustomLinkPage = false) {
        let userDetails = this.dataService.getLoginResp();
        let isCPAdmin = userDetails ? userDetails.SOURCE == "cpadmin" : false;
        let isCSApp = userDetails ? userDetails.SOURCE == "CS_APP" : false;
        let action = this._utils.getLocalData("action", false) ;
        localStorage.clear();
        this.dataService.clearCache();  
        if (isCPAdmin) {
            window.close();
            setTimeout(() => this.router.navigate(['/login']), 10);
        } else if (action === 'SA') {
            this.router.navigate(['/surrender-aversion']);
        } else if (action == 'SUR_INVEST') {
            this.router.navigate(["/investigator"]);
        } else if (action === 'COVID' || action === 'PHS' || action === 'MAT_CLAIM' || action == "STMNT") {
            this.router.navigate(['/my-link'], {queryParams: {action: action}, queryParamsHandling: "merge"});
        } else if (action === 'COVID_EXIDE') {
            this.router.navigate(['/dcq']);
        } else if (this.dataService.isCustomLink && !skipCustomLinkPage) {
            this.router.navigate(['/my-link'], {queryParams: {action: action}, queryParamsHandling: "preserve"});
        } else if(mode == 'S'){
            //session logout after clicking ok button user will navigate to the login page.
        } else {
            this.router.navigate(['/login']);
        }           
            
        let payload = mode == 'M' ? {"event": "LogOut"} : {"event": "TimeOut"}
        if (isCSApp) {
            try {
                sendToIOS(payload)
            } catch(e) { }
            try {
                sendToAndroid(payload)
            } catch(e) { }                     
            try {
                sendToReactNative(payload)
            } catch(e) { } 
        }
        if(mode == 'S'){            
            //session logout after clicking ok button user will navigate to the login page.
        } else{
            this.dialog.closeAll();
        }
        this.sessionTimer.clearInterval();
    }
}
